import moment from 'moment';

export const compare = (dateTimeA, dateTimeB) => {
  const momentA = moment(dateTimeA, 'DD/MM/YYYY');
  const momentB = moment(dateTimeB, 'DD/MM/YYYY');
  if (momentA <= momentB) return true;
  if (momentA >= momentB) return true;
  return 0;
};

export const dateInPast = (firstDate, secondDate) => {
  if (firstDate >= secondDate) {
    return true;
  }

  return false;
};
