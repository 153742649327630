import React from 'react';
import '../Registration/styles.css';
import { Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidenav from '../../components/Sidenav/Sidenav';
import documentLogo1 from '../../images/finance-logo-1.svg';
import documentLogo2 from '../../images/finance-logo-2.svg';
import documentLogoActive1 from '../../images/finance-logo-1-active.svg';
import documentLogoActive2 from '../../images/finance-logo-2-active.svg';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Finances = () => {
  const classes = useStyles();
  const mail = document.createElement('a');
  mail.href = `mailto:hello@derwentlondon.com?subject=Finance Enquiry`;

  return (
    <div className={classes.root}>
      <Header />
      <div className="tenantHomePage">
        <div className="enqBrchure">
          <Button onClick={() => mail.click()}>
            Need <br /> Help?{' '}
          </Button>
        </div>
        <div className="tenantHomeWrap">
          <Sidenav selectedFinance />
          <div className="contactsMain">
            <div className="buildingSelectContact">
              <h2>View Your Finances</h2>
            </div>

            <div className="documentSubHead">
              <p>
                Here you will find a summary of your various financial
                interactions with us. If there is something specific you are
                looking for please get in touch.
              </p>
            </div>
            <div className="contactsIn financeIn">
              <ul>
                <li>
                  <Link to="/credit-debit-statements-list">
                    <div className="financeLogo">
                      <img src={documentLogo2} alt="documentLogo2" />
                      <img src={documentLogoActive2} alt="documentLogo2" />
                    </div>
                    <div className="documentsContent">
                      <h5>Credit + Debit</h5>
                      <h6>All of your charges in one place</h6>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/arrears-statements-list">
                    <div className="financeLogo">
                      <img src={documentLogo1} alt="documentLogo1" />
                      <img src={documentLogoActive1} alt="documentLogo1" />
                    </div>
                    <div className="documentsContent">
                      <h5>Arrears</h5>
                      <h6>All of your charges in one place</h6>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Finances;
