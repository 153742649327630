import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import dowmArw from '../../images/down-arw.svg';
import '../ManageStaff/ManageStaff.css';
import './finance.css';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import Footer from '../../components/Footer/Footer';
import DCFilter from '../ManageStaff/DCFilter';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { GlobalContext } from '../../contaxt/GlobalContext';
import { priceLocalFormate } from '../../utils/constants';

const columns = [
  {
    id: 'firstName',
    SortOrder: 'desc',
    SortBy: 'firstName',
    label: 'Demand Date',
    minWidth: 135,
    maxWidth: 260,
  },
  {
    id: 'jobTitle',
    SortOrder: 'desc',
    SortBy: 'jobTitle',
    label: 'Debtor ID',
    minWidth: 110,
    maxWidth: 220,
  },
  {
    id: 'village',
    SortOrder: 'desc',
    SortBy: 'building.name',
    label: 'Invoice no',
    minWidth: 110,
    maxWidth: 130,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'role',
    SortOrder: 'desc',
    SortBy: 'role',
    label: 'From ',
    minWidth: 60,
    maxWidth: 120,
    format: value => value.toFixed(2),
  },

  {
    id: 'access',
    SortOrder: 'desc',
    SortBy: 'accessToCustomerApp',
    label: 'To',
    minWidth: 60,
    maxWidth: 80,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'payToAccount',
    SortOrder: 'desc',
    SortBy: 'payToAccount',
    label: 'Description',
    minWidth: 110,
    maxWidth: 170,
    format: value => value.toFixed(2),
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'Narrative',
    minWidth: 100,
    maxWidth: 110,
    format: value => value.toFixed(2),
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'Net £',
    align: 'right',
    paddingRight: '25px !important',
    minWidth: 90,
    maxWidth: 90,
    format: value => value.toFixed(2),
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'VAT £ ',
    align: 'right',
    paddingRight: '25px !important',
    minWidth: 90,
    maxWidth: 90,
    format: value => value.toFixed(2),
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'Gross £ ',
    align: 'right',
    paddingRight: '25px !important',
    minWidth: 90,
    maxWidth: 130,
    format: value => value.toFixed(2),
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'Received £ ',
    align: 'right',
    paddingRight: '25px !important',
    minWidth: 110,
    maxWidth: 130,
    format: value => value.toFixed(2),
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'Outstanding £',
    align: 'right',
    paddingRight: '25px !important',
    minWidth: 135,
    maxWidth: 180,
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
  },
  container: {
    maxHeight: 'calc(100vh - 257px)',
  },
  tableHeaderCell: {
    background: '#fff',
    border: 'none',
    padding: '12px 20px 12px 15px',
    color: '#000',
    fontSize: '12px',
    lineHeight: '17px',
    fontFamily: 'Conv_AtlasGrotesk-Regular-App',
    cursor: 'pointer',
    fontWeight: 'normal',
    borderBottom: '1px solid #dfdfdf',
    borderTop: '1px solid #dfdfdf',
    textTransform: 'capitalize',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '5px',
      top: '53%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: 'rgba(189, 189, 189, 0.6)',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '50%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
    },
    '&:nth-child(6)': {
      '&:after': {
        display: 'none',
      },
    },
    '&:nth-child(7)': {
      '&:after': {
        display: 'none',
      },
    },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  editIcon: {
    margin: '0 auto',
    cursor: 'pointer',
  },
  accountBoxImg: {
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& li': {
        width: '31%',
        '& img': {
          margin: '0 auto',
          width: '25px',
        },
      },
    },
  },
  tableBody: {
    verticalAlign: 'top',
    '& th': {
      fontSize: '12px',
      color: '#838384',
      fontWeight: 'normal',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      textTransform: 'capitalize',
      padding: '8px 16px',
      borderBottom: '1px solid #dfdfdf',
      height: '62px',
      verticalAlign: 'middle',
    },
    '& td': {
      fontSize: '12px',
      color: '#838384',
      fontWeight: 'normal',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      textTransform: 'capitalize',
      padding: '8px 16px',
      borderBottom: '1px solid #dfdfdf',
      height: '62px',
      verticalAlign: 'middle',
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#b8b8b9',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#b8b8b9',
  },
  buildVillg: {
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  addIcon: {
    position: 'absolute',
    zIndex: '999',
    right: '55px',
    bottom: '55px',
  },
  fab: {
    background: 'transparent !important',
    color: '#fff',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5) !important',
    width: '70px',
    height: '70px',
    '&:hover': {
      background: 'transparent',
      color: '#fff',
    },
  },
  pageListMain: {
    position: 'relative',
  },
  popupBadge: {
    background: '#fff',
    borderRadius: '0',
    fontSize: '15px',
    color: '#444444',
    width: 'auto',
    margin: '0 auto',
    position: 'fixed',
    top: '75px',
    display: 'table',
    transform: 'translateX(-50%)',
    left: '50%',
    right: 0,
    textAlign: 'center',
    padding: ' 20px 40px',
    boxShadow: '1px 3px 11px 1px rgba(0, 0, 0, 0.14)',
    zIndex: '999',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '0',
    padding: '12px 15px 0',
    borderTop: '1px solid #dfdfdf',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#444444',
        fontSize: '13px',
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    color: '#b8b8b9',
  },
  customerNameNew: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    '& img': {
      width: '29px',
    },
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle',
  },
  CircularProgress: {
    position: 'fixed',
  },
});

const headers = [
  { label: 'Demand Date', key: 'demandDate' },
  { label: 'Debtor reference', key: 'debtorReference' },
  { label: 'Invoice number', key: 'invoiceNumber' },
  { label: 'From', key: 'from' },
  { label: 'To', key: 'to' },
  { label: 'Transaction description', key: 'transactionDescription' },
  { label: 'Narrative', key: 'narrative' },
  { label: 'Transaction Amount Net', key: 'transactionAmountNet' },
  { label: 'Transaction Amount VAT ', key: 'transactionAmountVAT' },
  { label: 'Transaction Amount Gross', key: 'transactionAmountGross' },
  { label: 'Amount Received', key: 'amountReceived' },
];

const CreditDebitList = () => {
  const { tenantId, logOut } = useContext(GlobalContext);
  const classes = useStyles();
  const [creditDebitStmtList, setCreditDebitStmtList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blueBoxRefId, setBlueBoxRefId] = useState('');

  useEffect(() => {
    setLoading(true);
    if (!blueBoxRefId) {
      ApiClient.apiGet(`${apiEndPoints.getTenantDetails}/${tenantId}`)
        .then(data => {
          const blueBoxRef = data && data.data && data.data.data;
          setBlueBoxRefId(blueBoxRef && blueBoxRef.blueBoxReference);
        })
        .catch(error => {
          if (error.response.status.toString() === '440') logOut();
          const err = error;
          if (error.response.status.toString() !== '440')
            alert(
              err &&
                err.response &&
                err.response.data &&
                err.response.data.message,
            );
        });
    }
    if (blueBoxRefId) {
      ApiClient.apiGet(`${apiEndPoints.getArrears}/${blueBoxRefId}/Statement`)
        .then(data => {
          setCreditDebitStmtList(data && data.data && data.data.data);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error, 'error');
        });
    }
  }, [blueBoxRefId]);

  const exportData =
    creditDebitStmtList &&
    creditDebitStmtList.map(
      creditDebitStmt =>
        creditDebitStmt &&
        creditDebitStmt.transactions &&
        creditDebitStmt.transactions.map(row => {
          return {
            demandDate: moment(row.date).format('DD MMM YYYY'),
            debtorReference: creditDebitStmt.debtorReference,
            invoiceNumber: '--',
            from: moment(row.from).format('DD MMM YYYY'),
            to: moment(row.to).format('DD MMM YYYY'),
            transactionDescription: row.type || '--',
            narrative: row.narrative || '--',
            transactionAmountNet: priceLocalFormate(row.net),
            transactionAmountVAT: priceLocalFormate(row.vat),
            transactionAmountGross: priceLocalFormate(row.net + row.vat),
            amountReceived: (0).toFixed(2),
          };
        }),
    );

  const debitValStyles = value =>
    `${/[-]/.test(value) ? 'rowEllips grossAdded' : 'rowEllips'}`;

  const isDebitedValue = debitValue =>
    `${
      /[-]/.test(debitValue)
        ? `(${priceLocalFormate(debitValue).replace('-', '')})`
        : priceLocalFormate(debitValue)
    }`;

  const breadCrumbs = {
    backToPath: '/finances',
    currentPage: 'Your Finances: Credit + Debit Statements',
    currentPagePath: 'credit-debit-statements-list',
  };

  return (
    <>
      <Header />
      <div className={`${classes.pageListMain} pagecontainer`}>
        <Breadcrumb breadCrumbs={breadCrumbs} />
        <DCFilter
          availableFilters
          disabled
          exportData={exportData && exportData.length > 0 ? exportData : []}
          headers={headers}
          fileName="Credit Debit statement"
        />
        <div className="pageContainer tableContainer tenantUserListTable">
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        className={
                          classes.tableHeaderCell
                          //   `${
                          //   classes.tableHeaderCell
                          // }
                          //  noSort ${
                          //    active && column.SortBy === sortBy ? 'active' : ''
                          //  } `
                        }
                        key={column.id}
                        disabled
                        align={column.align}
                        id={column.label}
                        style={{ minWidth: column.minWidth }}
                      >
                        <p>{column.label}</p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {loading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <TableBody>
                    {creditDebitStmtList && creditDebitStmtList.length > 0 ? (
                      creditDebitStmtList &&
                      creditDebitStmtList.map(creditDebitStmt => (
                        <>
                          {creditDebitStmt &&
                            creditDebitStmt.transactions &&
                            creditDebitStmt.transactions.map(transaction => (
                              <TableRow className={classes.tableBody}>
                                <TableCell component="th" scope="row">
                                  <div className="rowEllips">
                                    {moment(transaction.date).format(
                                      'DD MMM YYYY',
                                    ) || '--'}
                                  </div>
                                </TableCell>
                                <TableCell align="left">
                                  <div className="rowEllips">
                                    {creditDebitStmt.debtorReference || '--'}
                                  </div>
                                </TableCell>
                                <TableCell align="left">
                                  <div className="rowEllips">--</div>
                                </TableCell>
                                <TableCell align="left">
                                  <div className="rowEllips">
                                    {moment(transaction.from).format(
                                      'DD MMM YYYY',
                                    ) || '--'}
                                  </div>
                                </TableCell>
                                <TableCell align="left">
                                  <div className="rowEllips">
                                    {' '}
                                    {moment(transaction.to).format(
                                      'DD MMM YYYY',
                                    ) || '--'}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={classes.accountBoxImgColumnActive}
                                  align="left"
                                  style={{ maxWidth: 110, width: 110 }}
                                >
                                  {' '}
                                  <Tooltip title="">
                                    <div className="rowEllips" title={transaction.type || ''}>
                                      {transaction.type || '--'}
                                    </div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  className={classes.accountBoxImgColumnActive}
                                  align="left"
                                  style={{ maxWidth: 100, width: 100 }}
                                >
                                  <Tooltip title={transaction.narrative || ''}>
                                    <div className="rowEllips">
                                      {transaction.narrative || '--'}
                                    </div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  className={classes.accountBoxImgColumnActive}
                                  align="right"
                                >
                                  <div
                                    className={debitValStyles(transaction.net)}
                                  >
                                    {isDebitedValue(transaction.net)}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={classes.accountBoxImgColumnActive}
                                  align="right"
                                >
                                  <div
                                    className={debitValStyles(transaction.vat)}
                                  >
                                    {isDebitedValue(transaction.vat)}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={classes.accountBoxImgColumnActive}
                                  align="right"
                                >
                                  <div
                                    className={debitValStyles(
                                      transaction.net + transaction.vat,
                                    )}
                                  >
                                    {isDebitedValue(
                                      transaction.net + transaction.vat,
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={classes.accountBoxImgColumnActive}
                                  align="right"
                                >
                                  <div className="rowEllips">
                                    {(0).toFixed(2)}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={classes.accountBoxImgColumnActive}
                                  align="right"
                                >
                                  <div className="rowEllips">
                                    {priceLocalFormate(
                                      transaction.net + transaction.vat,
                                    )}
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                        </>
                      ))
                    ) : (
                      <NoResultsFound />
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default CreditDebitList;
