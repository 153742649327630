import { combineReducers } from 'redux';
import newsAnnouncementDataReducer from './newsAnnouncementDataReducer';
import tenantDetailsReducer from './tenantDetailsReducer';
import buildingsReducer from './buildingsReducer';
import derwentBuildingsReducer from './derwentBuildingsReducer';
import spaceReducer from './spaceReducer';

const rootReducer = combineReducers({
  newsAnnouncementDataReducer,
  tenantDetailsReducer,
  buildingsReducer,
  derwentBuildingsReducer,
  spaceReducer,
});

export default rootReducer;
