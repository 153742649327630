/* eslint-disable import/named */
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import Header from '../components/Header/Header';
import HomePage from '../container/HomePage/HomePage';
// import ManageStaff from '../container/ManageStaff/ManageStaff';
import ForgotPassword from '../container/Registration/ForgotPassword';
import newsDetailsPage from '../container/HomePage/newsDetailsPage';
import Login from '../container/Registration/Login';
import ManageStaffContextProvider from '../contaxt/ManageStaffContext';
import CreateTenantUser from '../container/ManageStaff/CreateOrEditStaff';
import Contacts from '../container/Contacts/Contacts';
import TenantDocuments from '../container/Documents/Documents';
import Finances from '../container/Finances/Finances';
import Space from '../container/Space/Space';
import VillageBuildings from '../container/Space/VillageBuildings';
import BuildingUnits from '../container/Space/BuildingUnits';
import BuildingUnitDetails from '../container/Space/BuildingUnitDetails';
import CreditDebitList from '../container/Finances/CreditDebitStatements';
import ArrearsStamentList from '../container/Finances/ArrearsStatements';

const Routes = () => {
  // const [open, setOpen] = useState(false);
  // const isLogin = localStorage.getItem('loginToken');

  return (
    <Router>
      {/* {isLogin && <Header open={open} setOpen={setOpen} />} */}
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/homepage" component={HomePage} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route
          exact
          path="/manage-staff-list"
          component={ManageStaffContextProvider}
        />
        <Route exact path="/reset/:id" component={ForgotPassword} />
        {/* <Route exact path="/newsDetails/:id" component={newsDetailsPage} /> */}
        <Route exact path="/create-staff-user" component={CreateTenantUser} />
        <Route exact path="/edit-staff-user/:id" component={CreateTenantUser} />
        <Route
          exact
          path="/newsdetails/:name/:id"
          component={newsDetailsPage}
        />
        <Route
          exact
          path="/announcementdetails/:name/:id"
          component={newsDetailsPage}
        />
        <Route exact path="/contacts" component={Contacts} />
        <Route exact path="/documents" component={TenantDocuments} />
        <Route exact path="/finances" component={Finances} />
        <Route
          exact
          path="/credit-debit-statements-list"
          component={CreditDebitList}
        />
        <Route
          exact
          path="/arrears-statements-list"
          component={ArrearsStamentList}
        />
        <Route exact path="/space" component={Space} />
        <Route
          exact
          path="/:villageName/buildings"
          component={VillageBuildings}
        />
        <Route exact path="/:buildingunits/:id" component={BuildingUnits} />
        <Route
          exact
          path="/building/:buildingName/:unitName?/:id"
          component={BuildingUnitDetails}
        />
        <Route exact path="/availablespaces" component={BuildingUnits} />
      </Switch>
    </Router>
  );
};

export default Routes;
