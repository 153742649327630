import React, { useContext, useEffect, useState } from 'react';
import '../Registration/styles.css';
import './space.css';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import urlencode from 'urlencode';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { getSpacesAction } from '../../Actions/getSpacesAction';
import { getMinMax } from '../../utils/common';
import Footer from '../../components/Footer/Footer';
import { GlobalContext } from '../../contaxt/GlobalContext';

const useStyles = makeStyles(() => ({
  root: {},
}));

const VillageBuildings = () => {
  const { logOut } = useContext(GlobalContext);
  const { villageName } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [spaceData, setSpaceData] = useState([]);

  // Get space list.
  useEffect(() => {
    const spacePayload = {
      limit: 100,
      offset: 0,
      searchText: '',
      sortBy: 'unitSort',
      sortOrder: 'asc',
      status: ['Published'],
    };
    setIsLoading(true);
    ApiClient.apiPost(apiEndPoints.getSpacesList, spacePayload)
      .then(data => {
        setIsLoading(false);
        setSpaceData(data && data.data && data.data.data);
        dispatch(getSpacesAction(data && data.data && data.data.data));
      })
      .catch(error => {
        if (error.response.status.toString() === '440') logOut();
        setIsLoading(false);
        const err = error;
        if (error.response.status.toString() !== '440')
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
      });
  }, []);

  const listofDerwentBuildings = useSelector(
    state =>
      state &&
      state.derwentBuildingsReducer &&
      state.derwentBuildingsReducer.derwentBuildingsReducer,
  );
  const selectedVillageBuildings =
    listofDerwentBuildings &&
    listofDerwentBuildings.filter(item =>
      item.village.includes(' ')
        ? item.village.replace(' ', '-').toLowerCase() === villageName
        : item.village.toLowerCase() === villageName,
    );
  const village = `${villageName
    .split('-')[0]
    .charAt(0)
    .toUpperCase()}${villageName.split('-')[0].slice(1)}`;
  const VillageEnd = `${villageName
    .split('-')[1]
    .charAt(0)
    .toUpperCase()}${villageName.split('-')[1].slice(1)}`;
  return (
    <div className={classes.root}>
      <Header />
      <div className="tenantHomePage">
        <div className="tenantHomeWrap">
          <Sidenav selectedSpace />
          <div className="contactsMain">
            <div className="villageHeadingSticky">
              <div className="villageHeading">
                <h2>Our Derwent Buildings</h2>
              </div>

              <div className="villgSubHead">
                <p>
                  Buildings in{' '}
                  <span>
                    {villageName.includes('-')
                      ? `${village} ${VillageEnd}`
                      : `${villageName
                          .charAt(0)
                          .toUpperCase()}${villageName.slice(1)}`}
                  </span>{' '}
                  In addition to our 5.5m square foot London portfolio, private
                  office space on flexible leases from 12 months is available.
                </p>
              </div>
            </div>
            <div className="villageIn">
              <ul>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  selectedVillageBuildings &&
                  selectedVillageBuildings.map(item => {
                    const minMaxValue = getMinMax(spaceData, item.id);
                    return (
                      <>
                        {minMaxValue.length > 0 && (
                          <li key={item.id}>
                            <Link
                              to={`/building/${urlencode(
                                item && item.name,
                              ).toLowerCase()}/${item.id}`}
                            >
                              {item && item.images && item.images.length > 0 ? (
                                <div className="villageLogo">
                                  <img
                                    src={
                                      item &&
                                      item.images &&
                                      item.images[0] &&
                                      item.images[0].image &&
                                      item.images[0].image[0].imageUrl
                                    }
                                    alt={
                                      item &&
                                      item.images &&
                                      item.images[0] &&
                                      item.images[0].image &&
                                      item.images[0].image[0].imageUrl
                                    }
                                  />
                                </div>
                              ) : (
                                <div className="buildingNoprevImg">
                                  <h2>IMAGE COMING SOON</h2>
                                </div>
                              )}
                              <div className="spaceBuildingDetails">
                                <div className="villageName">
                                  <span>{item.name}</span>
                                  {minMaxValue && minMaxValue !== undefined && (
                                    <h5>
                                      {`${
                                        minMaxValue &&
                                        minMaxValue.length > 0 &&
                                        minMaxValue
                                          .reduce((a, b) => {
                                            return Math.min(a, b);
                                          })
                                          .toLocaleString()
                                      } - ${
                                        minMaxValue &&
                                        minMaxValue.length > 0 &&
                                        minMaxValue
                                          .reduce((a, b) => {
                                            return Math.max(a, b);
                                          })
                                          .toLocaleString()
                                      }`}{' '}
                                      sq ft
                                    </h5>
                                  )}
                                </div>
                                <div className="unitsAvlb">
                                  {`${
                                    minMaxValue.length > 1
                                      ? `${minMaxValue.length} units available`
                                      : `${minMaxValue.length} unit available`
                                  }`}
                                </div>
                              </div>
                            </Link>
                          </li>
                        )}
                      </>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default VillageBuildings;
