import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { CircularProgress, Tooltip } from '@material-ui/core';
import dowmArw from '../../images/down-arw.svg';
import Header from '../../components/Header/Header';
import DCFilter from '../ManageStaff/DCFilter';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/Footer/Footer';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { GlobalContext } from '../../contaxt/GlobalContext';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import { priceLocalFormate } from '../../utils/constants';

const useStyles = makeStyles({
  root: {
    width: '120%',
    boxShadow: 'none',
  },
  container: {
    maxHeight: 'calc(100vh - 320px)',
  },
  tableHeaderCell: {
    background: '#fff',
    border: 'none',
    padding: '12px 20px 12px 16px',
    color: '#000',
    fontSize: '12px',
    lineHeight: '17px',
    fontFamily: 'Conv_AtlasGrotesk-Regular-App',
    cursor: 'pointer',
    fontWeight: 'normal',
    borderBottom: '1px solid #dfdfdf',
    borderTop: '1px solid #dfdfdf',
    textTransform: 'capitalize',
    height: 'auto !important',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '5px',
      top: '53%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: 'rgba(189, 189, 189, 0.6)',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '50%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
    },
    // '&:nth-child(6)': {
    //   '&:after': {
    //     display: 'none',
    //   },
    // },
    // '&:nth-child(7)': {
    //   '&:after': {
    //     display: 'none',
    //   },
    // },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  editIcon: {
    margin: '0 auto',
    cursor: 'pointer',
  },
  accountBoxImg: {
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& li': {
        width: '31%',
        '& img': {
          margin: '0 auto',
          width: '25px',
        },
      },
    },
  },
  tableBody: {
    verticalAlign: 'top',
    '& th': {
      fontSize: '13px',
      color: '#838384',
      fontWeight: 'normal',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      textTransform: 'capitalize',
      padding: '8px 30px 8px 16px',
      borderBottom: '1px solid #dfdfdf',
      height: '62px',
      verticalAlign: 'middle',
    },
    '& td': {
      fontSize: '13px',
      color: '#838384',
      fontWeight: 'normal',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      textTransform: 'capitalize',
      padding: '8px 16px 8px 16px !important',
      borderBottom: '1px solid #dfdfdf',
      height: '62px',
      verticalAlign: 'middle',
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#b8b8b9',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#b8b8b9',
  },
  buildVillg: {
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  addIcon: {
    position: 'absolute',
    zIndex: '999',
    right: '55px',
    bottom: '55px',
  },
  fab: {
    background: 'transparent !important',
    color: '#fff',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5) !important',
    width: '70px',
    height: '70px',
    '&:hover': {
      background: 'transparent',
      color: '#fff',
    },
  },
  pageListMain: {
    position: 'relative',
  },
  popupBadge: {
    background: '#fff',
    borderRadius: '0',
    fontSize: '15px',
    color: '#444444',
    width: 'auto',
    margin: '0 auto',
    position: 'fixed',
    top: '75px',
    display: 'table',
    transform: 'translateX(-50%)',
    left: '50%',
    right: 0,
    textAlign: 'center',
    padding: ' 20px 40px',
    boxShadow: '1px 3px 11px 1px rgba(0, 0, 0, 0.14)',
    zIndex: '999',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '0',
    padding: '12px 15px 0',
    borderTop: '1px solid #dfdfdf',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#444444',
        fontSize: '13px',
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    color: '#b8b8b9',
  },
  customerNameNew: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    '& img': {
      width: '29px',
    },
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle',
  },
  CircularProgress: {
    position: 'fixed',
  },
});

const headers = [
  { label: 'Debtor Reference', key: 'debtorReference' },
  { label: 'Building', key: 'building' },
  { label: 'Items', key: 'items' },
  { label: 'Due Now', key: 'dueNow' },
  { label: 'Due in 1 Month', key: 'duein1Month' },
  { label: 'Due in 2 Months', key: 'duein2Month' },
  { label: 'Due in 3 Months', key: 'duein3Month' },
  { label: 'Due in 3+ Months ', key: 'duein3PlusMonth' },
  { label: 'Total', key: 'Total' },
];

const ArrearsStamentList = () => {
  const { tenantId, logOut } = useContext(GlobalContext);
  const classes = useStyles();
  const [arrearsList, setArrearsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blueBoxRefId, setBlueBoxRefId] = useState('');

  useEffect(() => {
    setLoading(true);
    if (!blueBoxRefId) {
      ApiClient.apiGet(`${apiEndPoints.getTenantDetails}/${tenantId}`)
        .then(data => {
          const blueBoxRef = data && data.data && data.data.data;
          setBlueBoxRefId(blueBoxRef && blueBoxRef.blueBoxReference);
        })
        .catch(error => {
          if (error.response.status.toString() === '440') logOut();
          const err = error;
          if (error.response.status.toString() !== '440')
            alert(
              err &&
                err.response &&
                err.response.data &&
                err.response.data.message,
            );
        });
    }
    if (blueBoxRefId) {
      ApiClient.apiGet(`${apiEndPoints.getArrears}/${blueBoxRefId}/Arrears`)
        .then(data => {
          setArrearsList(data && data.data && data.data.data);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error, 'error');
        });
    }
  }, [blueBoxRefId]);

  const isExportValZero = value =>
    value !== 0 ? priceLocalFormate(value) : '--';

  const exportData =
    arrearsList &&
    arrearsList.map(
      arrearsStmt =>
        arrearsStmt &&
        arrearsStmt.arrears &&
        arrearsStmt.arrears.map(row => {
          return {
            debtorReference: arrearsStmt.debtorReference,
            building: arrearsStmt.demise,
            items: row.type,
            dueNow: isExportValZero(row.dueNow),
            duein1Month: isExportValZero(row.due1Month),
            duein2Month: isExportValZero(row.due2Month),
            duein3Month: isExportValZero(row.due3Month),
            duein3PlusMonth: isExportValZero(row.due3PlusMonths),
            Total: isExportValZero(row.total),
          };
        }),
    );

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    const rowExpandToggle = () => setOpen(!open);
    const isPriceZero = value =>
      value !== 0 ? `£${priceLocalFormate(value)}` : '--';

    return (
      <>
        <TableRow
          className={`${classes.tableBody} ${
            open && 'expandedTableRow'
          } expandableTableRow`}
        >
          <TableCell
            style={{ maxWidth: 180, width: 160, paddingLeft: 0 }}
            onClick={rowExpandToggle}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={rowExpandToggle}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            {row.debtorReference}
          </TableCell>
          <TableCell
            style={{ maxWidth: 300, width: 300 }}
            onClick={rowExpandToggle}
          >
            <Tooltip title={row.demise}>
              <div className="twoRowEllips">{row.demise}</div>
            </Tooltip>
          </TableCell>
          <TableCell
            style={{ maxWidth: 200, width: 160 }}
            align="right"
            onClick={rowExpandToggle}
          >
            {isPriceZero(row.dueNow)}
          </TableCell>
          <TableCell
            style={{ maxWidth: 200, width: 160 }}
            align="right"
            onClick={rowExpandToggle}
          >
            {isPriceZero(row.due1Month)}
          </TableCell>
          <TableCell
            style={{ maxWidth: 200, width: 160 }}
            align="right"
            onClick={rowExpandToggle}
          >
            {isPriceZero(row.due2Month)}
          </TableCell>
          <TableCell
            style={{ maxWidth: 200, width: 160 }}
            align="right"
            onClick={rowExpandToggle}
          >
            {isPriceZero(row.due3Month)}
          </TableCell>
          <TableCell
            style={{ maxWidth: 200, width: 180 }}
            align="right"
            onClick={rowExpandToggle}
          >
            {isPriceZero(row.due3PlusMonths)}
          </TableCell>
          <TableCell
            style={{ maxWidth: 200, width: 120 }}
            align="right"
            onClick={rowExpandToggle}
          >
            {isPriceZero(row.total)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
            colSpan={8}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={0}>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {row &&
                      row.arrears &&
                      row.arrears.map(historyRow => (
                        <>
                          <TableRow
                            key={historyRow.type}
                            className={classes.tableBody}
                          >
                            <TableCell style={{ maxWidth: 180, width: 160 }}>
                              &nbsp;
                            </TableCell>
                            <TableCell
                              style={{ maxWidth: 300, width: 300 }}
                              align="left"
                            >
                              {historyRow.type}
                            </TableCell>
                            <TableCell
                              style={{ maxWidth: 200, width: 160 }}
                              align="right"
                            >
                              {isPriceZero(historyRow.dueNow)}
                            </TableCell>
                            <TableCell
                              style={{ maxWidth: 200, width: 160 }}
                              align="right"
                            >
                              {isPriceZero(historyRow.due1Month)}
                            </TableCell>
                            <TableCell
                              style={{ maxWidth: 200, width: 160 }}
                              align="right"
                            >
                              {isPriceZero(historyRow.due2Month)}
                            </TableCell>
                            <TableCell
                              style={{ maxWidth: 200, width: 160 }}
                              align="right"
                            >
                              {isPriceZero(historyRow.due3Month)}
                            </TableCell>
                            <TableCell
                              style={{ maxWidth: 200, width: 180 }}
                              align="right"
                            >
                              {isPriceZero(historyRow.due3PlusMonths)}
                            </TableCell>
                            <TableCell
                              style={{ maxWidth: 200, width: 120 }}
                              align="right"
                            >
                              {isPriceZero(historyRow.total)}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const breadCrumbs = {
    backToPath: '/finances',
    currentPage: 'Your finances : Arrears Statement',
    currentPagePath: 'arrears-statements-list',
  };

  return (
    <>
      <Header />
      <div className={`${classes.pageListMain} pagecontainer`}>
        <Breadcrumb breadCrumbs={breadCrumbs} />
        <DCFilter
          exportData={exportData && exportData.length > 0 ? exportData : []}
          headers={headers}
          fileName="Arrears statement"
        />
        <div className="pageContainer tableContainer tenantUserListTable arrerstatementTable">
          <TableContainer component={Paper} className={`${classes.container} arrerstatementTableIn`}>
            <Table stickyHeader aria-label="collapsible sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ maxWidth: 180, width: 160 }}
                    className={classes.tableHeaderCell}
                  >
                    Debtor ID
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: 300, width: 300 }}
                    className={classes.tableHeaderCell}
                    align="left"
                  >
                    Building
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: 200, width: 160 }}
                    className={classes.tableHeaderCell}
                    align="right"
                  >
                    Due now
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: 200, width: 160 }}
                    className={classes.tableHeaderCell}
                    align="right"
                  >
                    Due in 1 month
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: 200, width: 160 }}
                    className={classes.tableHeaderCell}
                    align="right"
                  >
                    Due in 2 months
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: 200, width: 160 }}
                    className={classes.tableHeaderCell}
                    align="right"
                  >
                    Due in 3 months
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: 200, width: 180 }}
                    className={classes.tableHeaderCell}
                    align="right"
                  >
                    Due in 3 + months
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: 200, width: 120 }}
                    className={classes.tableHeaderCell}
                    align="right"
                  >
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                (arrearsList && arrearsList.length > 0 && (
                  <>
                    <TableBody>
                      {arrearsList &&
                        arrearsList.map(row => (
                          <Row key={row.name} row={row} />
                        ))}
                    </TableBody>
                  </>
                )) || <NoResultsFound />
              )}
            </Table>
          </TableContainer>
          {!loading && arrearsList && arrearsList.length > 0 && (
            <TableContainer
              component={Paper}
              className={`${classes.container} TotalTableContainer`}
            >
              <Table stickyHeader aria-label="collapsible sticky table">
                <TableHead>
                  <TableRow className={classes.tableBody}>
                    <TableCell
                      style={{ maxWidth: 180, width: 160, paddingRight: 0 }}
                    >
                      Overall total
                    </TableCell>
                    <TableCell
                      style={{ maxWidth: 300, width: 300 }}
                      align="left"
                    >
                      &nbsp;
                    </TableCell>
                    <TableCell
                      style={{ maxWidth: 200, width: 160 }}
                      align="right"
                    >
                      --
                    </TableCell>
                    <TableCell
                      style={{ maxWidth: 200, width: 160 }}
                      align="right"
                    >
                      --
                    </TableCell>
                    <TableCell
                      style={{ maxWidth: 200, width: 160 }}
                      align="right"
                    >
                      --
                    </TableCell>
                    <TableCell
                      style={{ maxWidth: 200, width: 160 }}
                      align="right"
                    >
                      --
                    </TableCell>
                    <TableCell
                      style={{ maxWidth: 200, width: 180 }}
                      align="right"
                    >
                      --
                    </TableCell>
                    <TableCell
                      style={{ maxWidth: 200, width: 120 }}
                      align="right"
                    >
                      --
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArrearsStamentList;
