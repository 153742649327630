import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { save, load } from 'redux-localstorage-simple';
import rootReducer from '../Reducres/index';
// import logger from 'redux-logger';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
// const loggerMiddleware = createLogger();

export default function configureStore(preloadedState = load()) {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware, save()));
  const composedEnhancers = compose(enhancer);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);
  return store;
}
