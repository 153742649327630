import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core';
import './popup.css';

const useStyles = makeStyles(() => ({
  popupModalSmallBtn: {
    '& button': {
      fontSize: '16px',
      lineHeight: '47px',
      height: '47px',
      textAlign: 'center',
      color: '#fff',
      background: '#000',
      fontFamily: 'Conv_AtlasGrotesk-Medium-App',
      textTransform: 'inherit',
      width: 'auto',
      borderRadius: '0',
      padding: '0 42px',
      '&:hover': {
        background: '#000',
        boxShadow: 'none',
      },
    },
  },
}));

const Popup = props => {
  const { open, setOpen, setBlock, block, setStatus } = props;
  const classes = useStyles();

  const handleClickUpdate = () => {
    setBlock(!block);
    setStatus('Active');
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        disableBackdropClick
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="popupModalSmall">
          <div className="popupModalSmallCont">
            <h3>Are you sure you want to block this person?</h3>
            <p>
              This means they will not have access to the DL/ app, nor the
              Tenant Portal.{' '}
            </p>
          </div>
          <div className={`${classes.popupModalSmallBtn} popupModalSmallBtn`}>
            <Button onClick={handleClose} color="primary">
              Yes, block them
            </Button>
            <Button onClick={handleClickUpdate} color="primary" autoFocus>
              No, don’t
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Popup;
