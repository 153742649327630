import React, { useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import './filter.css';
import dowmArw from '../../images/down-arw.svg';
import exportIcon from '../../images/export-icon.svg';
import Pagination from '../../components/Pagination/Pagination';

const useStyles = makeStyles(() => ({
  filterMain: {
    margin: '0 0 0',
    padding: '0 25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterLeft: {
    width: '80%',
    '& ul': {
      display: 'flex',
      alignItems: 'center',
      '& li': {
        width: '17%',
        marginLeft: '25px',
        '&:first-child': {
          marginLeft: '0',
        },
        '&:last-child': {
          '& button': {
            fontSize: '13px',
            lineHeight: '18px',
            color: '#838384',
            fontFamily: 'Conv_AtlasGrotesk-Regular-App',
            textTransform: 'capitalize',
            backgroundColor: 'transparent',
            // borderBottom: '1px solid #dfdfdf',
            borderRadius: 0,
            padding: 0,
          },
        },
        '& label': {
          fontSize: '13px',
          lineHeight: '18px',
          color: '#838384',
          fontFamily: 'Conv_AtlasGrotesk-Regular-App',
        },
      },
    },
  },
  formControl: {
    width: '100%',
  },
  formSelectBox: {
    marginTop: '0 !important',
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      width: '100%',
      borderBottom: '1px solid #dfdfdf',
      fontSize: '13px',
      lineHeight: '18px',
      color: '#838384',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      background: 'transparent !important',
      '&:after': {
        position: 'absolute',
        content: '""',
        background: `url(${dowmArw}) no-repeat 95% center !important`,
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        height: '10px',
        width: '10px',
      },
      '& div': {
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  filterRgt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  filterSearch: {
    position: 'relative',
    '& > div': {
      width: '100%',
      borderBottom: '1px solid #dfdfdf',
      '& div': {
        marginTop: '0 !important',
        paddingRight: '35px',
        fontSize: '13px',
        lineHeight: '18px',
        color: '#838384',
        fontFamily: 'Conv_AtlasGrotesk-Regular-App',
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
    '& span': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '5px',
      width: '10px',
      cursor: 'pointer',
    },
  },
}));

const DCFilter = ({
  availableFilters,
  disabled,
  pagination,
  exportData,
  headers,
  fileName,
}) => {
  const classes = useStyles();
  const [csvReport, setCsvReport] = useState({
    data: [],
    headers: [],
    filename: '',
  });
  const date = new Date();
  const merged = exportData.flat(Infinity);

  const exportCsv = () => {
    const objReport = {
      filename: `${fileName} ${moment(date).format('YYYYMMDD HHmm')}.csv`,
      headers,
      data: merged,
    };
    setCsvReport(objReport);
  };

  return (
    <div className={classes.filterMain}>
      <div className={classes.filterLeft}>
        <ul>
          {availableFilters && (
            <>
              <li>
                <FormControl className={classes.formControl}>
                  <InputLabel>Demand Date</InputLabel>
                  <Select
                    className={classes.formSelectBox}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    disabled={disabled}
                  >
                    <MenuItem className="multiSelectMenu">dfgdfgd</MenuItem>
                    <MenuItem className="multiSelectMenu">dfgdfgd</MenuItem>
                  </Select>
                </FormControl>
              </li>
              <li>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Debtor ID
                  </InputLabel>
                  <Select
                    className={classes.formSelectBox}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    disabled={disabled}
                  >
                    <MenuItem className="multiSelectMenu">zfdsfsdfsd</MenuItem>
                  </Select>
                </FormControl>
              </li>

              <li>
                <Button>Clear filters</Button>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className={classes.filterRgt}>
        {pagination && (
          <div className={classes.filterPagination}>
            <Pagination page="1" setPage="10" setOffset="10" totalCount="80" />
          </div>
        )}

        <div className="exportdcList">
          <Button
            onClick={() => {
              exportCsv();
            }}
          >
            {exportData && exportData.length > 0 && (
              <CSVLink
                data={csvReport.data}
                headers={csvReport.headers}
                filename={csvReport.filename}
              >
                <img src={exportIcon} alt="exportIcon" />
              </CSVLink>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default DCFilter;
