import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import GlobalContextProvider from './contaxt/GlobalContext';
import './index.css';
import { configureStore } from './Store/index';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import Routes from './routes/Routes';

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <GlobalContextProvider>
        <Routes />
      </GlobalContextProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
