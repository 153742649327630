import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core';
import '../../container/Registration/styles.css';
import { Link, useHistory } from 'react-router-dom';
import buttonCard1 from '../../images/buttons-card-1.svg';
import buttonCard2 from '../../images/buttons-card-2.svg';
import buttonCard3 from '../../images/buttons-card-3.svg';
import buttonCard4 from '../../images/buttons-card-4.svg';
import buttonCard5 from '../../images/buttons-card-5.svg';
import buttonCard1active from '../../images/buttons-card-1-active.svg';
import buttonCard2active from '../../images/buttons-card-2-active.svg';
import buttonCard3active from '../../images/buttons-card-3-active.svg';
import buttonCard4active from '../../images/buttons-card-4-active.svg';
import buttonCard5active from '../../images/buttons-card-5-active.svg';
import { GlobalContext } from '../../contaxt/GlobalContext';

const useStyles = makeStyles(() => ({
  formButton: {
    marginTop: '60px',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      height: '47px',
      lineHeight: '47px',
      border: '1px solid #000',
      outline: 'none',
      background: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      width: 'auto',
      padding: '0 45px',
      color: '#fff',
      marginLeft: '30px',
      cursor: 'pointer',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '&:hover': {
        background: '#000000',
      },
      '&:first-child': {
        background: 'transparent',
        color: '#444444',
        border: 'none',
      },
    },
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  popupFormButton: {
    marginTop: '45px !important',
  },
}));

const Sidenav = props => {
  const { selected, selectedSpace, selectedFinance, selectedDocuments } = props;
  const { rolePermission } = useContext(GlobalContext);
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    history.push('/homepage');
  };

  return (
    <div className="tenantPortalNav">
      <List className="leftNav">
        {(rolePermission && rolePermission.arrears) ||
        (rolePermission && rolePermission.statement) ? (
          <ListItem className={selectedFinance && 'Mui-selected'}>
            <Link to="/finances">
              <span>
                <img src={buttonCard1} alt="buttonCard1" />
                <img src={buttonCard1active} alt="buttonCard1" />
              </span>
              <div className="navText">
                Finances
                <small>Statement of account</small>
              </div>
            </Link>
            <div className="borderLine">&nbsp;</div>
          </ListItem>
        ) : (
          <ListItem
            className={selectedFinance && 'Mui-selected'}
            onClick={() => setOpen(true)}
          >
            <Link>
              <span>
                <img src={buttonCard1} alt="buttonCard1" />
                <img src={buttonCard1active} alt="buttonCard1" />
              </span>
              <div className="navText">
                Finances
                <small>Statement of account</small>
              </div>
            </Link>
            <div className="borderLine">&nbsp;</div>
          </ListItem>
        )}

        <ListItem className={selected && 'Mui-selected'}>
          <Link to="/contacts">
            <span>
              <img src={buttonCard2} alt="buttonCard2" />
              <img src={buttonCard2active} alt="buttonCard1" />
            </span>
            <div className="navText">
              Contacts
              <small>Your Derwent address book</small>
            </div>
          </Link>
          <div className="borderLine">&nbsp;</div>
        </ListItem>
        <ListItem className={selectedDocuments && 'Mui-selected'}>
          <Link to="/documents">
            <span>
              <img src={buttonCard3} alt="buttonCard3" />
              <img src={buttonCard3active} alt="buttonCard1" />
            </span>
            <div className="navText">
              Documents
              <small>Your paperwork and building info</small>
            </div>
          </Link>
          <div className="borderLine">&nbsp;</div>
        </ListItem>
        <ListItem>
          <Link to="/manage-staff-list">
            <span>
              <img src={buttonCard4} alt="buttonCard4" />
              <img src={buttonCard4active} alt="buttonCard1" />
            </span>
            <div className="navText">
              DL/ App Management
              <small>Add, view and manage your staff </small>
            </div>
          </Link>
          <div className="borderLine">&nbsp;</div>
        </ListItem>
        <ListItem className={selectedSpace && 'Mui-selected'}>
          <Link to="/space">
            <span>
              <img src={buttonCard5} alt="buttonCard5" />
              <img src={buttonCard5active} alt="buttonCard1" />
            </span>
            <div className="navText">
              Furnished + Flexible
              <small>Grow your business with us</small>
            </div>
          </Link>
          <div className="borderLine">&nbsp;</div>
        </ListItem>
      </List>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="accessDialogOpend"
      >
        <div className={classes.cancelDialog}>
          <h2>Access Denied</h2>
        </div>
        <DialogActions className={`${classes.formButton} accessDialogBtn`}>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Sidenav;
