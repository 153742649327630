/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, Tooltip } from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import dowmArw from '../../images/down-arw.svg';
import './ManageStaff.css';
import newIcon from '../../images/new.svg';
import newHoverIcon from '../../images/new-hover.svg';
import Header from '../../components/Header/Header';
import Filter from './filter';
import { ManageStaffContext } from '../../contaxt/ManageStaffContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import ViewStaffDetails from './ViewStaffDetails';
import { GlobalContext } from '../../contaxt/GlobalContext';
import Footer from '../../components/Footer/Footer';
import infoIcon from '../../images/buttons-info-icon.svg';
import apiEndPoints from '../../helpers/apiEndPoints';
import ApiClient from '../../helpers/ApiClient';
import { getTenantDetails } from '../../Actions/getTenantDetailsAction';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#444444',
    maxWidth: 190,
    width: '190 !important',
    color: '#fff',
    height: 'auto',
    border: 'none !important',
    fontSize: '11px',
    lineHeight: '18px',
    borderRadius: '10px',
    padding: '0 15px 15px 15px',
    fontFamily: 'Conv_AtlasGrotesk-Light-App',
    fontWeight: 'normal',
    boxShadow: 'none !important',
  },
  arrow: {
    color: '#444',
    fontSize: 20,
    // marginTop: 30,
    // position: 'relative',
    '&:before': {
      border: 'none !important',
    },
  },
}))(Tooltip);
const infoTitleData = (
  <div className="infoTitleData">
    <p>
      A super administrator has full access to all features of the Derwent London member portal.
    </p>
    <p>
      An employee has no access to the member portal but can use the DL/ App
    </p>
  </div>
);

const columns = [
  {
    id: 'firstName',
    SortOrder: 'desc',
    SortBy: 'firstName',
    label: 'Name',
    minWidth: 260,
    maxWidth: 260,
  },
  {
    id: 'jobTitle',
    SortOrder: 'desc',
    SortBy: 'jobTitle',
    label: 'Job Role',
    minWidth: 140,
    maxWidth: 180,
  },
  {
    id: 'village',
    SortOrder: 'desc',
    SortBy: 'building.name',
    label: 'Location',
    minWidth: 130,
    maxWidth: 130,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'role',
    SortOrder: 'desc',
    SortBy: 'role',
    label: 'Portal Role ' && (
      <div className="tooltipInfo">
        {' '}
        <p>Portal Role </p>{' '}
        <HtmlTooltip
          title={infoTitleData}
          arrow
          placement="right-start"
          classes={{ tooltip: 'custom-width' }}
          PopperProps={{
            popperOptions: {
              modifiers: {
                offset: {
                  enabled: true,
                  offset: '-0px -10px',
                },
              },
            },
          }}
        >
          <span className="tooltipInfoIcon">
            {' '}
            <img src={infoIcon} alt={infoIcon} />{' '}
          </span>
        </HtmlTooltip>{' '}
      </div>
    ),
    minWidth: 120,
    maxWidth: 120,
    format: value => value.toFixed(2),
  },

  {
    id: 'access',
    SortOrder: 'desc',
    SortBy: 'accessToCustomerApp',
    label: 'Member Portal Access',
    minWidth: 170,
    maxWidth: 200,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'payToAccount',
    SortOrder: 'desc',
    SortBy: 'payToAccount',
    label: 'Pay to account ',
    minWidth: 140,
    maxWidth: 150,
    format: value => value.toFixed(2),
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'Status',
    minWidth: 110,
    maxWidth: 110,
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
  },
  container: {
    maxHeight: 'calc(100vh - 294px)',
  },
  tableHeaderCell: {
    background: '#fff',
    border: 'none',
    padding: '12px 15px',
    color: '#000',
    fontSize: '13px',
    lineHeight: '17px',
    fontFamily: 'Conv_AtlasGrotesk-Regular-App',
    cursor: 'pointer',
    fontWeight: 'normal',
    borderBottom: '1px solid #dfdfdf',
    borderTop: '1px solid #dfdfdf',
    textTransform: 'capitalize',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: 'rgba(189, 189, 189, 0.6)',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '50%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      // '&:after': {
      //   display: 'none',
      // },
    },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  editIcon: {
    margin: '0 auto',
    cursor: 'pointer',
  },
  accountBoxImg: {
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& li': {
        width: '31%',
        '& img': {
          margin: '0 auto',
          width: '25px',
        },
      },
    },
  },
  tableBody: {
    verticalAlign: 'top',
    '& th': {
      fontSize: '13px',
      color: '#838384',
      fontWeight: 'normal',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      textTransform: 'capitalize',
      padding: '8px 16px',
      borderBottom: '1px solid #dfdfdf',
    },
    '& td': {
      fontSize: '13px',
      color: '#838384',
      fontWeight: 'normal',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      textTransform: 'capitalize',
      padding: '8px 16px',
      borderBottom: '1px solid #dfdfdf',
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#b8b8b9',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#b8b8b9',
  },
  buildVillg: {
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  addIcon: {
    position: 'absolute',
    zIndex: '999',
    right: '15px',
    bottom: '15px',
  },
  fab: {
    background: 'transparent !important',
    color: '#fff',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5) !important',
    width: '60px',
    height: '60px',
    '&:hover': {
      background: 'transparent',
      color: '#fff',
    },
  },
  pageListMain: {
    position: 'relative',
  },
  popupBadge: {
    background: '#fff',
    borderRadius: '0',
    fontSize: '15px',
    color: '#444444',
    width: 'auto',
    margin: '0 auto',
    position: 'fixed',
    top: '75px',
    display: 'table',
    transform: 'translateX(-50%)',
    left: '50%',
    right: 0,
    textAlign: 'center',
    padding: ' 20px 40px',
    boxShadow: '1px 3px 11px 1px rgba(0, 0, 0, 0.14)',
    zIndex: '999',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '0',
    padding: '12px 15px 0',
    borderTop: '1px solid #dfdfdf',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#444444',
        fontSize: '13px',
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    color: '#b8b8b9',
  },
  customerNameNew: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    '& img': {
      width: '29px',
    },
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle',
  },
  CircularProgress: {
    position: 'fixed',
  },
});

const TenentUserList = () => {
  const {
    tenantsUsersList,
    tenantActiveUsers,
    loading,
    setSortOrder,
    sortOrder,
    setSortBy,
    sortBy,
  } = useContext(ManageStaffContext);
  const {
    tenantUserSuccessmsg,
    setTenantUserSuccesssMsg,
    setTenantUserName,
    setTenantId,
    setBuildingId,
    setIsPatyToAcc,
    setPermissionsStatus,
    logOut,
  } = useContext(GlobalContext);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [tenantUserDetails, setTenantUserDetails] = useState({});
  const decoded = jwt_decode(localStorage.getItem('authorizationToken'));
  

  useEffect(() => {
    setTimeout(() => {
      setTenantUserSuccesssMsg('');
    }, 3000);
  }, [tenantUserSuccessmsg]);

  // get getRolePermission API Call
  const getRolePermission = () => {
    ApiClient.apiGet(apiEndPoints.getRolePermission)
      .then(data => {
        const permissionData = [data.data.data];
        const myHour = new Date();
        myHour.setHours(myHour.getHours() + 24);
        permissionData.push(myHour);
        localStorage.setItem('rolePermission', JSON.stringify(permissionData));

        setPermissionsStatus(true);
      })
      .catch(error => {
        if (error.response.status.toString() === '440') logOut();
        console.log(error, 'error');
      });
    // }
  };
  function checkExpiration() {
    const values = JSON.parse(localStorage.getItem('rolePermission'));
    if (values && values[1] < new Date()) {
      localStorage.removeItem('rolePermission');
    }
  }

  function myFunction() {
    const myinterval = 15 * 60 * 1000; // 15 min interval
    // eslint-disable-next-line func-names
    setInterval(function () {
      checkExpiration();
    }, myinterval);
  }

  myFunction();
  useEffect(() => {
    if (localStorage.getItem('rolePermission') === null) {
      getRolePermission();
    } else {
      setPermissionsStatus(true);
      // logOut();
    }
  }, []);

  useEffect(() => {
    if (decoded) {
      ApiClient.apiGet(`${apiEndPoints.getTenantUser}${decoded && decoded.oid}`)
        .then(data => {
          const tenantDetails = data && data.data && data.data.data;
          dispatch(getTenantDetails(tenantDetails));
          setTenantUserName(
            `${tenantDetails && tenantDetails.firstName}${' '}${
              tenantDetails && tenantDetails.lastName
            }`,
          );
          setIsPatyToAcc(tenantDetails && tenantDetails.payToAccount);
          setTenantId(
            tenantDetails && tenantDetails.tenant && tenantDetails.tenant.id,
          );
          setBuildingId(
            tenantDetails &&
              tenantDetails.building &&
              tenantDetails.building.id,
          );
        })
        .catch(error => {
          if (error.response.status.toString() === '440') logOut();
          const err = error;
          if (error.response.status.toString() !== '440')
            alert(
              err &&
                err.response &&
                err.response.data &&
                err.response.data.message,
            );
        });
    }
  }, []);

  const getUserCreatedDate = createDate => {
    const date1 = new Date().getTime();
    const date2 = new Date(createDate).getTime();
    const msDiff = date1 - date2;
    const minutesDifference = Math.floor(msDiff / 60000);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    return daysDifference;
  };

  const handleClick = (columnId, sortName) => {
    setSortOrder(sortOrder === columnId ? 'asc' : 'desc');
    setSortBy(sortBy === sortName ? sortBy : sortName);
  };

  const viewUser = data => {
    setTenantUserDetails(data);
    setOpen(true);
  };

  const breadCrumbs = {
    // backToPath: '/homepage',
    currentPage: 'View Your Staff',
    currentPagePath: 'manage-staff-list',
  };

  return (
    <>
      <Header />
      <div className={`${classes.pageListMain} pagecontainer`}>
        {tenantUserSuccessmsg && (
          <div className={classes.popupBadge}>{tenantUserSuccessmsg}</div>
        )}
        <Breadcrumb breadCrumbs={breadCrumbs} />
        <Filter />
        <div className="pageContainer tableContainer tenantUserListTable">
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        className={`${classes.tableHeaderCell} noSort ${
                          active && column.SortBy === sortBy ? 'active' : ''
                        } `}
                        key={column.id}
                        disabled
                        align={column.align}
                        id={column.label}
                        onClick={() => {
                          if (
                            column.SortBy !== '' ||
                            column.SortBy === sortBy
                          ) {
                            handleClick(column.SortOrder, column.SortBy);
                            setActive(!active);
                          }
                        }}
                        style={{ minWidth: column.minWidth }}
                      >
                        <p>{column.label}</p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    className={classes.CircularProgress}
                  />
                ) : (
                  <TableBody>
                    {tenantsUsersList && tenantsUsersList.length > 0 ? (
                      tenantsUsersList.map(row => (
                        <TableRow key={row.id} className={classes.tableBody}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ maxWidth: 260, width: 260 }}
                            onClick={() => {
                              viewUser(row);
                            }}
                          >
                            <div className="rowEllips">
                              <Tooltip
                                className="rowEllips"
                                title={`${row.firstName} ${row.lastName}`}
                              >
                                <div className={classes.customerName}>
                                  {' '}
                                  {`${row.firstName} ${row.lastName}`}
                                  {getUserCreatedDate(row.createdDate) < 30 && (
                                    <span className={classes.customerNameNew}>
                                      <img src={newIcon} alt="buttonsAdd" />
                                      <img
                                        src={newHoverIcon}
                                        alt="newHoverIcon"
                                      />
                                    </span>
                                  )}
                                </div>
                              </Tooltip>
                              <Tooltip title={row.email}>
                                <div className={classes.customerEmail}>
                                  {row.email}
                                </div>
                              </Tooltip>
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ maxWidth: 180, width: 140 }}
                            onClick={() => {
                              viewUser(row);
                            }}
                          >
                            <div className="rowEllips">{row.jobTitle}</div>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ maxWidth: 180, width: 180 }}
                            onClick={() => {
                              viewUser(row);
                            }}
                          >
                            <div className="rowEllips">
                              <Tooltip title={row.building.name}>
                                <div className={classes.buildVillg}>
                                  {row.building.name}
                                </div>
                              </Tooltip>
                              <div className={classes.customerVillg}>
                                {row.building.village}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ maxWidth: 190, width: 170 }}
                            onClick={() => {
                              viewUser(row);
                            }}
                          >
                            <div className="rowEllips">{row.role}</div>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ maxWidth: 200, width: 180 }}
                            onClick={() => {
                              viewUser(row);
                            }}
                          >
                            <div className="rowEllips">
                              <div className={classes.buildVillg}>
                                {row.role === 'Employee' ? 'No' : 'Yes'}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.accountBoxImgColumnActive}
                            align="left"
                            style={{ maxWidth: 150, width: 140 }}
                            onClick={() => {
                              viewUser(row);
                            }}
                          >
                            {row.payToAccount === true ? 'Yes' : 'No'}
                          </TableCell>
                          <TableCell
                            className={classes.accountBoxImgColumnActive}
                            align="left"
                            style={{ maxWidth: 90, width: 90 }}
                            onClick={() => {
                              viewUser(row);
                            }}
                          >
                            {row.status}
                            <div className="rowEllips">
                              <div className={classes.userAddTime}>
                                {row.status === 'Blocked' && row.inactiveDate
                                  ? moment(row.inactiveDate).format(
                                      'DD MMM YYYY',
                                    )
                                  : ''}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <NoResultsFound />
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
          <div className={classes.totalCount}>
            <ul>
              <li>{`Total active users: ${tenantActiveUsers || 0}`}</li>
            </ul>
          </div>
        </div>
        <ViewStaffDetails
          open={open}
          setOpen={setOpen}
          tenantUserDetails={tenantUserDetails}
        />
      </div>
      <Footer />
    </>
  );
};
export default TenentUserList;
