/* eslint-disable prefer-const */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import '../Registration/styles.css';
import './space.css';
import moment from 'moment';
import { CircularProgress, makeStyles } from '@material-ui/core';
import urlencode from 'urlencode';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dowmArw from '../../images/down-arw.svg';
import deskIcon from '../../images/icons-tp-desks.svg';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import SpaceFilters from './SpaceFilters';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import Footer from '../../components/Footer/Footer';
import { GlobalContext } from '../../contaxt/GlobalContext';
import { getSpacesAction } from '../../Actions/getSpacesAction';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';

const useStyles = makeStyles(() => ({
  filterMain: {
    margin: '10px 0 0',
    padding: '0 25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterLeft: {
    width: '65%',
    '& ul': {
      display: 'flex',
      alignItems: 'center',
      '& li': {
        width: '20%',
        marginLeft: '25px',
        '&:first-child': {
          marginLeft: '0',
        },
        '&:last-child': {
          '& button': {
            fontSize: '14px',
            lineHeight: '18px',
            color: '#838384',
            fontFamily: 'Conv_AtlasGrotesk-Regular-App',
            textTransform: 'capitalize',
            backgroundColor: 'transparent',
            borderBottom: '1px solid #dfdfdf',
            borderRadius: 0,
            padding: 0,
          },
        },
        '& label': {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#838384',
          fontFamily: 'Conv_AtlasGrotesk-Regular-App',
        },
      },
    },
  },
  formControl: {
    width: '100%',
  },
  formSelectBox: {
    marginTop: '0 !important',
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      width: '100%',
      borderBottom: '1px solid #dfdfdf',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#838384',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      background: 'transparent !important',
      '&:after': {
        position: 'absolute',
        content: '""',
        background: `url(${dowmArw}) no-repeat 95% center !important`,
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        height: '10px',
        width: '10px',
      },
      '& div': {
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  filterRgt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  filterSearch: {
    position: 'relative',
    '& > div': {
      width: '100%',
      borderBottom: '1px solid #dfdfdf',
      '& div': {
        marginTop: '0 !important',
        paddingRight: '35px',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#838384',
        fontFamily: 'Conv_AtlasGrotesk-Regular-App',
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
    '& span': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 0,
      width: '13px',
      cursor: 'pointer',
    },
  },
}));

const BuildingUnits = () => {
  const classes = useStyles();
  const { logOut } = useContext(GlobalContext);
  const dispatch = useDispatch();
  const observer = useRef(null);
  const scrollToRef = useRef(null);
  const [offsetValue, setOffsetValue] = useState(0);
  const [selectedDate, setSelectedDate] = useState('');
  const [clearFilter, setClearFilter] = useState(false);
  const [spaceList, setSpaceList] = useState([]);
  const [spaceListCount, setSpaceListCount] = useState('');
  const [selectedVillages, setSelectedVillages] = useState([]);
  const todayDate = new Date().toISOString();
  const currentDate = moment(todayDate).format('YYYY-MM-DD');
  const [dateSelected, setDateSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deskOption, setDeskOption] = useState('');

  const listofDerwentBuildings = useSelector(
    state =>
      state &&
      state.derwentBuildingsReducer &&
      state.derwentBuildingsReducer.derwentBuildingsReducer,
  );
  let villageBuildngIds = [];
  if (selectedVillages.length > 0) {
    listofDerwentBuildings.forEach(village => {
      selectedVillages.forEach(item => {
        if (village.village === item) {
          villageBuildngIds.push(village.id);
        }
      });
    });
  }
  // Remove duplicate villages.
  const uniqueVillages = Array.from(
    new Set(
      listofDerwentBuildings && listofDerwentBuildings.map(a => a.village),
    ),
  ).map(id => {
    return listofDerwentBuildings.find(a => a.village === id);
  });

  useEffect(() => scrollToRef.current.scrollTo(0, 0), [
    villageBuildngIds && villageBuildngIds.length > 0,
    selectedDate,
  ]);

  const lastBookElementRef = useCallback(
    node => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && spaceListCount > offsetValue) {
          const offset = offsetValue + 20;
          setOffsetValue(
            spaceListCount > offset ? offsetValue + 20 : offsetValue,
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [
      loading,
      spaceListCount,
      offsetValue,
      villageBuildngIds && villageBuildngIds.length > 0,
      selectedVillages,
    ],
  );

  useEffect(() => {
    setLoading(true);
    const spacePayload = {
      buildings: villageBuildngIds.length > 0 ? villageBuildngIds : undefined,
      availability: selectedDate.length > 0 ? dateSelected : undefined,
      desk:
        deskOption.length > 0
          ? (deskOption === '20+' && [21, 999]) || [
              Number(deskOption.split('-')[0]),
              Number(deskOption.split('-')[1]),
            ]
          : undefined,
      limit: 20,
      offset: offsetValue,
      sortBy: 'unitSort',
      sortOrder: 'asc',
      status: ['Published'],
    };
    ApiClient.apiPost(apiEndPoints.getSpacesList, spacePayload)
      .then(data => {
        setLoading(false);
        setSpaceList(
          offsetValue !== 0
            ? spaceList.concat(data && data.data && data.data.data)
            : data && data.data && data.data.data,
        );
        dispatch(
          getSpacesAction(
            offsetValue !== 0
              ? spaceList.concat(data && data.data && data.data.data)
              : data && data.data && data.data.data,
          ),
        );
        setSpaceListCount(data && data.data && data.data.count);
      })
      .catch(error => {
        if (error.response.status.toString() === '440') logOut();
        setLoading(false);
        const err = error;
        if (error.response.status.toString() !== '440')
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
      });
  }, [
    villageBuildngIds && villageBuildngIds.length > 0,
    selectedVillages,
    selectedDate,
    clearFilter,
    offsetValue,
    observer,
    deskOption,
  ]);

  return (
    <div className={classes.root}>
      <Header loading={loading} />
      <div className="tenantHomePage">
        <div className="tenantHomeWrap" ref={scrollToRef}>
          <Sidenav selectedSpace />
          {loading && spaceList && spaceList.length === 0 ? (
            <CircularProgress />
          ) : (
            <div className="contactsMain">
              <div className="villageHeadingSticky">
                <div className="villageHeading">
                  <h2>All available units</h2>
                </div>

                <div className="villgSubHead">
                  <p>
                    Here are all of our units that match the criteria based on
                    your search.
                  </p>
                </div>
                <SpaceFilters
                  spaceList={spaceList}
                  offsetValue={offsetValue}
                  spaceListCount={spaceListCount}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  setClearFilter={setClearFilter}
                  selectedVillages={selectedVillages}
                  setSelectedVillages={setSelectedVillages}
                  uniqueVillages={uniqueVillages}
                  villageBuildngIds={villageBuildngIds}
                  currentDate={currentDate}
                  dateSelected={dateSelected}
                  setDateSelected={setDateSelected}
                  setOffsetValue={setOffsetValue}
                  setDeskOption={setDeskOption}
                  deskOption={deskOption}
                />
              </div>
              <div className="villageIn">
                <ul>
                  {!loading && spaceList && spaceList.length === 0 ? (
                    <NoResultsFound />
                  ) : (
                    spaceList &&
                    spaceList.map((item, index) => (
                      <li key={index} ref={lastBookElementRef}>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <Link
                            to={`/building/${urlencode(
                              item && item.building && item.building.name,
                            ).toLowerCase()}/${
                              item &&
                              item.unit
                                .replace(/[^a-zA-Z0-9]/g, '')
                                .toLowerCase()
                            }-${item.id}/${item.building.id}`}
                          >
                            {item && item.images && item.images.length > 0 && (
                              <div className="villageLogo">
                                <img
                                  src={
                                    item &&
                                    item.images &&
                                    item.images[0] &&
                                    item.images[0].image &&
                                    item.images[0].image[0].imageUrl
                                  }
                                  alt="buildingLogo1"
                                />
                              </div>
                            )}
                            <div className="spaceBuildingDetails">
                              <div className="villageName">
                                <span>
                                  {item && item.building && item.building.name}
                                </span>
                                <h5>
                                  {item && item.unit},{' '}
                                  {item && item.sizeAreaSqFt.toLocaleString()}{' '}
                                  sq ft
                                </h5>
                              </div>
                              <div className="deskImg">
                                <span>
                                  <img src={deskIcon} alt="deskIcon" />
                                </span>
                                <span>{item.desk}</span>
                              </div>
                            </div>
                          </Link>
                        )}
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default BuildingUnits;
