import React from 'react';
import moment from 'moment';
import '../../container/Registration/styles.css';
import 'font-awesome/css/font-awesome.css';
import { Link } from 'react-router-dom';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';

import './footer.css';

const Footer = () => {
  // Mailto.
  const mail = document.createElement('a');
  mail.href = 'mailto:hello@derwentlondon.com';

  return (
    <div className="tenantHomeFooter">
      <div className="pagecontainer">
        <div className="footerIn">
          <div className="footerNav">
            <ul>
              <li className="copyright">
                <Link>
                  {`© ${moment(new Date()).format('YYYY')}
                  Derwent London`}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    window.open(
                      'https://www.derwentlondon.com/texts/cookie-policy',
                      '_blank',
                    )
                  }
                >
                  Cookies
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    window.open(
                      'https://www.derwentlondon.com/uploads/downloads/Terms-policies/App-Privacy-Policy.pdf',
                      '_blank',
                    )
                  }
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    window.open(
                      'https://www.derwentlondon.com/uploads/downloads/Terms-policies/App-Terms.pdf',
                      '_blank',
                    )
                  }
                >
                  Terms + Conditions
                </Link>
              </li>
            </ul>
          </div>
          <div className="footerSocialIcon">
            <ul>
              <li>
                <Link
                  onClick={() =>
                    window.open('https://www.derwentlondon.com/', '_blank')
                  }
                >
                  <LanguageIcon />
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    window.open('https://twitter.com/derwentlondon', '_blank')
                  }
                >
                  <TwitterIcon />
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    window.open(
                      'https://www.facebook.com/DerwentLondon/',
                      '_blank',
                    )
                  }
                >
                  <FacebookIcon />
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    window.open(
                      'https://www.linkedin.com/company/derwent-london-plc',
                      '_blank',
                    )
                  }
                >
                  <LinkedInIcon />
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    window.open(
                      'https://www.instagram.com/accounts/login/',
                      '_blank',
                    )
                  }
                >
                  <InstagramIcon />
                </Link>
              </li>
              <li>
                <Link onClick={() => mail.click()}>
                  <EmailIcon />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
