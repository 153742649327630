/* eslint-disable no-useless-escape */
/* eslint-disable import/no-unresolved */
import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
  // ),
});
export const emailSchema = yup.object().shape({
  email: yup.string().email().required(),
});
export const forgotSchema = yup.object().shape({
  password: yup
    .string()
    .min(8)
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    ),
  // confirmPassword: yup.string().min(8).required(),
});

export const tenantUserDetailsSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  location: yup.string().required(),
  jobTitle: yup.string().required(),
  email: yup.string().email().required(),
  role: yup.string().required(),
  payToAccount: yup.string().required(),
});
