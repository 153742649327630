/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './AppendDots.css';
import './imageslider.css';
import playIcon from '../../images/play-button.svg';

function AppendDots(props) {
  const { selectedSpaceData } = props;
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const spaceData = selectedSpaceData && selectedSpaceData[0];

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    infinite: true,
    focusOnSelect: true,
    asNavFor: '.slider-nav',
  };

  const settingsThumbs = {
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: true,
    infinite: true,
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: false,
  };

  return (
    <>
      {(spaceData && spaceData.images && spaceData.images.length > 0) ||
      (spaceData && spaceData.floorPlan && spaceData.floorPlan > 0) ||
      (spaceData && spaceData.video) ? (
        <div className="App">
          <div className="slider-wrapper">
            <Slider
              {...settingsMain}
              asNavFor={nav2}
              ref={slider => setSlider1(slider)}
              className="slickMainSlider"
            >
              {spaceData.images &&
                spaceData.images.map(slide => {
                  return (
                    <div className="slick-slide ">
                      <div
                        className="buildingSlideImage"
                        style={{
                          backgroundImage: `url(${
                            slide.image && slide.image[0].imageUrl
                          })`,
                        }}
                      >
                        &nbsp;
                      </div>
                    </div>
                  );
                })}
              {spaceData.floorPlan && spaceData.floorPlan.length > 0 && (
                <div className="slick-slide ">
                  <div
                    className="buildingSlideImage"
                    style={{
                      backgroundImage: `url(${
                        spaceData.floorPlan && spaceData.floorPlan[0].imageUrl
                      })`,
                    }}
                  >
                    &nbsp;
                  </div>
                </div>
              )}
              {spaceData && spaceData.video && (
                <div className="slick-slide ">
                  <video
                    className="slick-slide-image"
                    poster={
                      spaceData &&
                      spaceData.previewImage &&
                      spaceData.previewImage[0].imageUrl
                    }
                    src={spaceData && spaceData.video}
                    controls
                    disablePictureInPicture
                    controlsList="nodownload"
                    preload="none"
                  />
                </div>
              )}
            </Slider>
            <div className="thumbnail-slider-wrap">
              <Slider
                {...settingsThumbs}
                asNavFor={nav1}
                ref={slider => setSlider2(slider)}
              >
                {spaceData &&
                  spaceData.images &&
                  spaceData.images.map(slide => {
                    return (
                      <div className="slick-slide slickSlideThumb">
                        <img
                          className="slick-slide-image"
                          src={slide.image && slide.image[0].imageUrl}
                          alt={slide.image && slide.image[0].imageUrl}
                        />
                      </div>
                    );
                  })}

                {spaceData &&
                  spaceData.floorPlan &&
                  spaceData.floorPlan.length > 0 && (
                    <div className="slick-slide slickSlideThumb">
                      <img
                        className="slick-slide-image"
                        src={
                          spaceData &&
                          spaceData.floorPlan &&
                          spaceData.floorPlan[0].imageUrl
                        }
                        alt={
                          spaceData &&
                          spaceData.floorPlan &&
                          spaceData.floorPlan[0].imageUrl
                        }
                      />
                    </div>
                  )}
                {spaceData && spaceData.previewImage && (
                  <div className="slick-slide slickSlideThumb videoSlideThumb">
                    <img
                      className="slick-slide-image"
                      src={
                        spaceData &&
                        spaceData.previewImage &&
                        spaceData.previewImage[0].imageUrl
                      }
                      alt="videoImage"
                    />
                    <div className="slideThumbPlay">
                      <img src={playIcon} alt="playIcon" />
                    </div>
                  </div>
                )}
              </Slider>
            </div>
          </div>
        </div>
      ) : (
        <div className="noGallery">
          <h2>IMAGE COMING SOON</h2>
        </div>
      )}
    </>
  );
}

export default AppendDots;
