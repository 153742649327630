/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import './filter.css';
import dowmArw from '../../images/down-arw.svg';
import Pagination from '../../components/Pagination/Pagination';
import searchLogo from '../../images/search.svg';
import closeIcon from '../../images/buttons-tp-x.svg';
import { ManageStaffContext } from '../../contaxt/ManageStaffContext';

const useStyles = makeStyles(() => ({
  filterMain: {
    margin: '0 0 0',
    padding: '0 25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterLeft: {
    width: '65%',
    '& ul': {
      display: 'flex',
      alignItems: 'center',
      '& li': {
        width: '17%',
        marginLeft: '25px',
        '&:first-child': {
          marginLeft: '0',
        },
        '&:last-child': {
          '& button': {
            fontSize: '13px',
            lineHeight: '18px',
            color: '#838384',
            fontFamily: 'Conv_AtlasGrotesk-Regular-App',
            textTransform: 'capitalize',
            backgroundColor: 'transparent',
            // borderBottom: '1px solid #dfdfdf',
            borderRadius: 0,
            padding: 0,
          },
        },
        '& label': {
          fontSize: '13px',
          lineHeight: '18px',
          color: '#838384',
          fontFamily: 'Conv_AtlasGrotesk-Regular-App',
        },
      },
    },
  },
  formControl: {
    width: '100%',
  },
  formSelectBox: {
    marginTop: '0 !important',
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      width: '100%',
      borderBottom: '1px solid #dfdfdf',
      fontSize: '13px',
      lineHeight: '18px',
      color: '#838384',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      background: 'transparent !important',
      '&:after': {
        position: 'absolute',
        content: '""',
        background: `url(${dowmArw}) no-repeat 95% center !important`,
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        height: '10px',
        width: '10px',
      },
      '& div': {
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  filterRgt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  filterSearch: {
    position: 'relative',
    '& > div': {
      width: '100%',
      borderBottom: '1px solid #dfdfdf',
      '& div': {
        marginTop: '0 !important',
        paddingRight: '35px',
        fontSize: '13px',
        lineHeight: '18px',
        color: '#838384',
        fontFamily: 'Conv_AtlasGrotesk-Regular-App',
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
    '& span': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '5px',
      width: '10px',
      cursor: 'pointer',
    },
  },
}));

const payToAcc = [true, false];
const roleValues = ['Super Administrator', 'Employee'];
const statusValues = ['Active', 'Inactive', 'Blocked'];

const Filter = () => {
  const {
    page,
    setPage,
    setOffset,
    totalTenants,
    payToAccount,
    setPayToAccount,
    role,
    setRole,
    status,
    setStatus,
    buildingDataList,
    searchText,
    setSearchText,
    location,
    setLocation,
  } = useContext(ManageStaffContext);
  const classes = useStyles();

  const clearFilters = () => {
    setLocation([]);
    setPayToAccount([]);
    setRole([]);
    setStatus([]);
  };

  return (
    <div className={classes.filterMain}>
      <div className={classes.filterLeft}>
        <ul>
          {buildingDataList && buildingDataList.length > 1 && (
            <li>
              <FormControl className={classes.formControl}>
                <InputLabel>Location</InputLabel>
                <Select
                  className={classes.formSelectBox}
                  multiple
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  value={location}
                  onChange={e => setLocation(e.target.value)}
                >
                  {buildingDataList &&
                    buildingDataList.map(item => (
                      <MenuItem
                        insetChildren
                        checked={item && item.name}
                        key={item.id}
                        value={item.id}
                        className="multiSelectMenu"
                        primaryText={item.name}
                      >
                        {`${item.name} (${item.village})`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </li>
          )}
          <li>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Pay To Account
              </InputLabel>
              <Select
                className={classes.formSelectBox}
                multiple
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                value={payToAccount}
                onChange={e => setPayToAccount(e.target.value)}
              >
                {payToAcc.map(name => (
                  <MenuItem key={name} value={name} className="multiSelectMenu">
                    {(name === true && 'Yes') || (name === false && 'No')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </li>
          <li>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                className={classes.formSelectBox}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                multiple
                value={role}
                onChange={e => setRole(e.target.value)}
              >
                {roleValues &&
                  roleValues.map(name => (
                    <MenuItem
                      key={name}
                      value={name}
                      className="multiSelectMenu"
                    >
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </li>
          <li>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                className={classes.formSelectBox}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                multiple
                value={status}
                onChange={e => setStatus(e.target.value)}
              >
                {statusValues &&
                  statusValues.map(name => (
                    <MenuItem
                      key={name}
                      value={name}
                      className="multiSelectMenu"
                    >
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </li>
          <li>
            <Button onClick={clearFilters}>Clear filters</Button>
          </li>
        </ul>
      </div>
      <div className={classes.filterRgt}>
        <div className={classes.filterPagination}>
          <Pagination
            page={page}
            setPage={setPage}
            setOffset={setOffset}
            totalCount={totalTenants}
          />
        </div>
        <div className={classes.filterSearch}>
          <TextField
            type="text"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            id="standard-basic"
            placeholder="Search"
          />
          {searchText && searchText.length > 0 ? (
            <span className="popupClose">
              <img
                src={closeIcon}
                alt="closeIcon"
                role="presentation"
                onClick={() => setSearchText('')}
              />
            </span>
          ) : (
            <span className="SearchIcon">
              <img src={searchLogo} alt="searchLogo" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default Filter;
