import React from 'react';

const NoResultsFound = () => {
  return (
    <div className="noResult">
      <h2>NO RESULTS FOUND</h2>
      <p>Please check your search and try again.</p>
    </div>
  );
};
export default NoResultsFound;
