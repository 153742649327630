/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { isUndefined } from 'lodash';
import { azureProfileFunKey } from '../utils/config';
import apiEndPoints from './apiEndPoints';

function getDefaultOptions() {
  return {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${JSON.parse(
        localStorage.getItem('authorizationToken'),
      )}`,
      'content-type': 'application/json',
      'x-functions-key': azureProfileFunKey,
    },
  };
}
function validateUser(res) {
  res.then(
    () => {},
    error => {
      if (isUndefined(error.status)) {
        // localStorage.clear();
        localStorage.setItem(
          'networkErr',
          'Something went wrong, please try again after some time.',
        );
        // window.location.href = '/';
        if (error && error.response && error.response.status === 409) {
          localStorage.setItem('email_check', 'Email already exists');
        }
      } else if (
        error.response.status === 401 &&
        apiEndPoints.getClentDetails !== error.response.config.url
      ) {
        // localStorage.clear();
        // window.location.href = '/';
      }
    },
  );
}
const ApiClient = {
  buildUrl(path) {
    return path;
  },

  apiGet(path) {
    const res = axios.get(path, getDefaultOptions());
    validateUser(res);
    return res;
  },
  apiPost(path, payload, options) {
    const optionsWithDefaults = { ...getDefaultOptions(), ...options };
    const res = axios.post(this.buildUrl(path), payload, optionsWithDefaults);
    validateUser(res);
    return res;
  },
  apiformPost(path, payload) {
    const formData = new FormData();
    formData.append('file', payload);
    const config = {
      headers: {
        accesstoken: `Web ${localStorage.getItem('accesstoken')}`,
        'content-type': 'multipart/form-data',
      },
    };
    const res = axios.post(this.buildUrl(path), formData, config);
    validateUser(res);
    return res;
  },
  apiPut(path, payload, options) {
    const optionsWithDefaults = { ...getDefaultOptions(), ...options };
    const res = axios.put(this.buildUrl(path), payload, optionsWithDefaults);
    validateUser(res);
    return res;
  },
  apiDelete(path, payload, options) {
    const optionsWithDefaults = {
      ...getDefaultOptions(),
      ...options,
      data: payload,
    };
    const res = axios.delete(this.buildUrl(path), optionsWithDefaults);
    validateUser(res);
    return res;
  },
};

export default ApiClient;
