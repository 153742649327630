import * as actionTypes from '../utils/constants';

const initialState = {};
const spaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SPACE_DETAILS: {
      const newState = {
        ...state,
      };
      newState.spaceReducer = action.action;
      return newState;
    }
    case actionTypes.GET_SPACE_DETAILS_COUNT: {
      const newState = {
        ...state,
      };
      newState.spaceReducerCount = action.action;
      return newState;
    }
    default: {
      return state;
    }
  }
};

export default spaceReducer;
