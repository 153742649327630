/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import '../Registration/styles.css';
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import documentLogo1 from '../../images/document-logo-1.svg';
import documentLogo2 from '../../images/document-logo-2.svg';
import documentLogo3 from '../../images/document-logo-3.svg';
import documentLogo4 from '../../images/document-logo-4.svg';
import documentLogo5 from '../../images/document-logo-5.svg';
import documentLogo6 from '../../images/document-logo-6.svg';
import documentLogo1active from '../../images/document-logo-1-active.svg';
import documentLogo2active from '../../images/document-logo-2-active.svg';
import documentLogo3active from '../../images/document-logo-3-active.svg';
import documentLogo4active from '../../images/document-logo-4-active.svg';
import documentLogo5active from '../../images/document-logo-5-active.svg';
import documentLogo6active from '../../images/document-logo-6-active.svg';
import dowmArw from '../../images/down-arw.svg';
import Footer from '../../components/Footer/Footer';

const useStyles = makeStyles(() => ({
  formSelectBox: {
    marginTop: '0 !important',
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      width: '100%',
      borderBottom: '1px solid #838384',
      fontSize: '15px',
      lineHeight: '20px',
      color: '#838384',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      background: 'transparent !important',
      padding: '0 0 2px 0',
      minWidth: '150px',
      '&:after': {
        position: 'absolute',
        content: '""',
        background: `url(${dowmArw}) no-repeat 95% center !important`,
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        height: '10px',
        width: '10px',
      },
      '& div': {
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
  },
}));

const Documents = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <Header />
      <div className="tenantHomePage">
        <div className="tenantHomeWrap">
          <Sidenav selectedDocuments/>
          <div className="contactsMain">
            <div className="buildingSelectContact">
              <h2>Your Documents for </h2>
              <FormControl className={classes.formControl}>
                <Select
                  className={classes.formSelectBox}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  value="123"
                >
                  <MenuItem value="123">80 Charlotte Street</MenuItem>
                  <MenuItem value="1234">80 Charlotte Street one</MenuItem>
                  <MenuItem value="12345">80 Charlotte Street two</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="documentSubHead">
              <p>
                Here yoi will find a range of different documents and FAQs
                related to your tenancy and your building. Please do not
                hesistate to get in touch if there is something specific you are
                looking for.
              </p>
            </div>
            <div className="contactsIn documentsIn">
              <ul>
                <li>
                  <Link>
                    <div className="documentLogo">
                      <img src={documentLogo1} alt="documentLogo1" />
                      <img src={documentLogo1active} alt="documentLogo1" />
                    </div>
                    <div className="documentsContent">
                      <h5>Service Charge</h5>
                      <h6>
                        View your service charge budgets, variance reports and
                        audit certificates
                      </h6>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link>
                    <div className="documentLogo">
                      <img src={documentLogo2} alt="documentLogo2" />
                      <img src={documentLogo2active} alt="documentLogo2" />
                    </div>
                    <div className="documentsContent">
                      <h5>Insurance</h5>
                      <h6>View your policy documents s</h6>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link>
                    <div className="documentLogo">
                      <img src={documentLogo3} alt="documentLogo3" />
                      <img src={documentLogo3active} alt="documentLogo3" />
                    </div>
                    <div className="documentsContent">
                      <h5>Lease</h5>
                      <h6>
                        Information about your leases for all of your buildings
                        with us{' '}
                      </h6>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link>
                    <div className="documentLogo">
                      <img src={documentLogo4} alt="documentLogo4" />
                      <img src={documentLogo4active} alt="documentLogo4" />
                    </div>
                    <div className="documentsContent">
                      <h5>Building</h5>
                      <h6>
                        Important day-to-day information about your office
                      </h6>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link>
                    <div className="documentLogo">
                      <img src={documentLogo5} alt="documentLogo5" />
                      <img src={documentLogo5active} alt="documentLogo5" />
                    </div>
                    <div className="documentsContent">
                      <h5>Fire Safety</h5>
                      <h6>
                        Guides to staying safe, and health and safety policies
                      </h6>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link>
                    <div className="documentLogo">
                      <img src={documentLogo6} alt="documentLogo6" />
                      <img src={documentLogo6active} alt="documentLogo6" />
                    </div>
                    <div className="documentsContent">
                      <h5>Contractor Information</h5>
                      <h6>Information for works and contractors</h6>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Documents;
