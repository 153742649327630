export const getMinMax = (spacelist, buildingId) => {
  const sqft = [];
  if (spacelist)
    for (let i = 0; i < spacelist.length; i++) {
      if (spacelist[i].building.id === buildingId) {
        sqft.push(spacelist[i].sizeAreaSqFt);
      }
    }

  return sqft;
};
