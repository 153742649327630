import { GET_SPACE_DETAILS, GET_SPACE_DETAILS_COUNT } from '../utils/constants';

const getSpacesDetails = action => ({
  type: GET_SPACE_DETAILS,
  action,
});

export const getSpacesAction = spaceDetails => dispatch => {
  dispatch(getSpacesDetails(spaceDetails));
};
const getSpacesDetailsCount = action => ({
  type: GET_SPACE_DETAILS_COUNT,
  action,
});

export const getSpacesCount = spaceDetails => dispatch => {
  dispatch(getSpacesDetailsCount(spaceDetails));
};
