import {
  GET_DERWENT_BUIDLINGS,
  GET_DERWENT_VILLAGE_BUIDLINGS,
} from '../utils/constants';

const getDerwentBuildingDetails = action => ({
  type: GET_DERWENT_BUIDLINGS,
  action,
});

export const getDerwentBuildingsAction = derwentBuildingDetails => dispatch => {
  dispatch(getDerwentBuildingDetails(derwentBuildingDetails));
};

const getBuildingsByVillageDetails = action => ({
  type: GET_DERWENT_VILLAGE_BUIDLINGS,
  action,
});

export const getBuildingsByVillageAction = derwentBuildingVillage => dispatch => {
  dispatch(getBuildingsByVillageDetails(derwentBuildingVillage));
};
