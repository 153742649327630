/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import '../Registration/styles.css';
import './space.css';
import { Button, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import urlencode from 'urlencode';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
// import ImageSlider from './ImageSider';
// import streetLogo from '../../images/street-logo.png';
import buttonCard1 from '../../images/buttons-card.svg';
import { getMinMax } from '../../utils/common';
import { MapContainer } from '../../components/Googlemap/MapContainer';
import { dateInPast } from '../../utils/dateFunctions';
import AppendDots from './ImageSider';
// import CustomSlider from './customSlider';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { nearByStationsRadius } from '../../utils/constants';
import Footer from '../../components/Footer/Footer';
import { GlobalContext } from '../../contaxt/GlobalContext';
import { getSpacesAction } from '../../Actions/getSpacesAction';

const useStyles = makeStyles(() => ({
  root: {},
}));

const BuildingUnitDetails = props => {
  const { logOut } = useContext(GlobalContext);
  const { match } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = match.params;
  const { unitName } = match.params;
  const { buildingName } = match.params;
  const [buildingSelected, setBuildingSelected] = useState(false);
  const [nearByStations, setNearByStations] = useState([]);
  // Get space list.
  const spaceData = useSelector(
    state => state && state.spaceReducer && state.spaceReducer.spaceReducer,
  );
  const buildingsData = useSelector(
    state =>
      state &&
      state.derwentBuildingsReducer &&
      state.derwentBuildingsReducer.derwentBuildingsReducer,
  );
  const postCodeOfBuilding = buildingsData.filter(item => item.id === id);

  const selectedSpaceData = spaceData.filter(
    item =>
      `${item.unit.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}-${item.id}` ===
      unitName,
  );

  const selectedUnitBuilding = spaceData.filter(
    item => item.building.id === id,
  );

  const postcode =
    buildingsData && buildingsData.filter(item => item.id === id);

  useEffect(() => {
    //  setLoading(true);
    const spacePayload = {
      buildings: [id],
      limit: 100,
      offset: 0,
      sortBy: 'unitSort',
      sortOrder: 'asc',
      status: ['Published'],
    };
    ApiClient.apiPost(apiEndPoints.getSpacesList, spacePayload)
      .then(data => {
        //  setLoading(false);

        dispatch(getSpacesAction(data && data.data && data.data.data));
      })
      .catch(error => {
        if (error.response.status.toString() === '440') logOut();
        //  setLoading(false);
        const err = error;
        if (error.response.status.toString() !== '440')
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
      });
  }, []);

  // Nearby stations api call
  useEffect(() => {
    // setIsLoading(true);
    ApiClient.apiGet(
      `${apiEndPoints.getNearbyStations}/${
        postcode && postcode[0].id
      }?radius=${nearByStationsRadius}`,
    )
      .then(data => {
        // setIsLoading(false);
        setNearByStations(data && data.data && data.data.data);
      })
      .catch(error => {
        if (error.response.status.toString() === '440') logOut();
        // setIsLoading(false);
        const err = error;
        if (error.response.status.toString() !== '440')
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
      });
  }, []);

  const breadCrumbs = {
    backToPath: !unitName
      ? `/${
          postCodeOfBuilding &&
          postCodeOfBuilding[0].village &&
          postCodeOfBuilding[0].village.replaceAll(' ', '-').toLowerCase()
        }/buildings`
      : '/availablespaces',
    currentPage: `Furnished + Flexible: ${
      postCodeOfBuilding &&
      postCodeOfBuilding[0] &&
      postCodeOfBuilding[0].village
    } `,
    currentPagePath: 'manage-staff-list',
  };

  // Mailto.
  const mail = document.createElement('a');
  mail.href = `mailto:hello@derwentlondon.com?subject=Enquiry of ${unitName} in ${
    selectedUnitBuilding &&
    selectedUnitBuilding[0] &&
    selectedUnitBuilding[0].building &&
    selectedUnitBuilding[0].building.name
  }`;

  // Download brochure.
  async function downloadImage(imageSrc) {
    const image = await fetch(imageSrc);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = 'Building_brochure';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className={classes.root}>
      <Header />
      <div className="tenantHomePage">
        {((!unitName &&
          postCodeOfBuilding &&
          postCodeOfBuilding[0].buildingBrochure) ||
          unitName) && (
          <div
            className={`${
              buildingSelected || !unitName
                ? postCodeOfBuilding &&
                  postCodeOfBuilding[0].buildingBrochure &&
                  'enqBrchure'
                : 'enqBrchure'
            }`}
          >
            <Button
              onClick={() => {
                buildingSelected || !unitName
                  ? downloadImage(
                      postCodeOfBuilding &&
                        postCodeOfBuilding[0].buildingBrochure,
                    )
                  : mail.click();
              }}
            >
              {buildingSelected || !unitName
                ? postCodeOfBuilding &&
                  postCodeOfBuilding[0].buildingBrochure &&
                  'Download Brochure'
                : 'Enquire'}
            </Button>
          </div>
        )}
        <div className="unitBreadcrumb">
          <Breadcrumb breadCrumbs={breadCrumbs} BuildingUnitDetails />
        </div>
        <div className="buildingFullDtls">
          <div className="buildingGallery">
            {/* <CustomSlider
              selectedSpaceData={
                buildingSelected || !unitName
                  ? postCodeOfBuilding
                  : selectedSpaceData
              }
            /> */}
            <AppendDots
              key={Date.now()}
              selectedSpaceData={
                buildingSelected || !unitName
                  ? postCodeOfBuilding
                  : selectedSpaceData
              }
            />
          </div>
          <div className="buildingDtlsIn">
            <div className="buildingDtlsInSticky">
              <h1>
                {selectedUnitBuilding &&
                  selectedUnitBuilding[0] &&
                  selectedUnitBuilding[0].building &&
                  selectedUnitBuilding[0].building.name}
                ,{' '}
                {postcode.length > 0 &&
                  postcode[0].postcode &&
                  postcode[0].postcode.split(' ')[0]}
              </h1>
              <div className="buildingFloors">
                <div className="cover-bar">&nbsp;</div>
                <ul>
                  <li>
                    <button
                      className={buildingSelected || !unitName ? 'active' : ''}
                      onClick={() => {
                        setBuildingSelected(true);
                        history.push(`/building/${buildingName}/${id}`);
                      }}
                    >
                      Building
                    </button>
                  </li>
                  {selectedUnitBuilding &&
                    selectedUnitBuilding.map(item => {
                      return (
                        <li key={item.id}>
                          <button
                            className={
                              `${item.unit
                                .replace(/[^a-zA-Z0-9]/g, '')
                                .toLowerCase()}-${item.id}` === unitName
                                ? 'active'
                                : ''
                            }
                            value={item.unit}
                            name={item.unit}
                            onClick={e => {
                              setBuildingSelected(false);
                              history.push(
                                `/building/${urlencode(
                                  item.building.name,
                                ).toLowerCase()}/${e.target.value
                                  .replace(/[^a-zA-Z0-9]/g, '')
                                  .toLowerCase()}-${item.id}/${
                                  item.building.id
                                }`,
                              );
                            }}
                          >
                            {item.unit}
                          </button>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            {buildingSelected || !unitName
              ? postCodeOfBuilding.map(item => {
                  const myPlaces = [
                    {
                      id: item && item.name,
                      pos: {
                        lat: item && item.latitude,
                        lng: item && item.longitude,
                      },
                    },
                  ];
                  const center = {
                    lat: item && item.latitude,
                    lng: item && item.longitude,
                  };
                  const minMaxValue = getMinMax(spaceData, item.id);
                  return (
                    <div className="floorFullDesc" key={item.id}>
                      <div className="floorWidth">
                        {minMaxValue && minMaxValue !== undefined && (
                          <>
                            {`${
                              minMaxValue &&
                              minMaxValue.length > 0 &&
                              minMaxValue
                                .reduce((a, b) => {
                                  return Math.min(a, b);
                                })
                                .toLocaleString()
                            } - ${
                              minMaxValue &&
                              minMaxValue.length > 0 &&
                              minMaxValue
                                .reduce((a, b) => {
                                  return Math.max(a, b);
                                })
                                .toLocaleString()
                            }`}{' '}
                            sq ft
                          </>
                        )}
                      </div>
                      <div className="floorWidth">
                        {`${
                          minMaxValue.length > 1
                            ? `${minMaxValue.length} units available`
                            : `${minMaxValue.length} unit available`
                        } `}
                      </div>
                      <div className="floorDesc">{item.description}</div>
                      <div className="floorFeature">
                        <ul>
                          {item &&
                            item.features &&
                            item.features.map(feature => {
                              return (
                                <li key={feature.id}>
                                  <span>
                                    <img
                                      src={
                                        (feature && feature.imageIcon) ||
                                        buttonCard1
                                      }
                                      alt="buttonCard1"
                                    />
                                  </span>
                                  <div className="featureText">
                                    {feature.name}
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                      <div className="stationLocation">
                        <h3>Location </h3>
                        <div className="stationMap">
                          <MapContainer
                            spaceMap
                            myPlaces={myPlaces}
                            centerProp={center}
                            buildingVillage={item.village}
                            addressLine1={item.addressLine1}
                            addressLine2={item.addressLine2}
                            county={item.county}
                            city={item.city}
                            postCode={item && item.postcode}
                          />
                        </div>
                      </div>
                      {nearByStations && nearByStations.length > 0 && (
                        <div className="BuildingStations">
                          <h3>Stations Nearby </h3>
                          <ul>
                            {nearByStations &&
                              nearByStations.map((nearByStation, index) => (
                                <>
                                  {index < 4 && (
                                    <>
                                      <li>
                                        <div
                                          className="streetDetails"
                                          key={nearByStation.id}
                                        >
                                          <div className="streetName">
                                            <h4>{nearByStation.name}</h4>
                                            <h5>
                                              {`${(
                                                nearByStation.distance *
                                                0.00062137
                                              ).toFixed(2)} mi`}
                                            </h5>
                                          </div>
                                          {/* <div className="streetLogo">
                                            <img
                                              src={streetLogo}
                                              alt="buttonCard1"
                                            />
                                          </div> */}
                                        </div>
                                        <div className="streetLane">
                                          {nearByStation.lines &&
                                            nearByStation.lines.map(line => (
                                              <p
                                                style={{
                                                  backgroundColor: `${line.hexColour}`,
                                                }}
                                              >
                                                {line.name}
                                              </p>
                                            ))}
                                        </div>
                                      </li>
                                    </>
                                  )}
                                </>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  );
                })
              : selectedSpaceData.map(item => {
                  const todayDate = new Date().toISOString();
                  const currentDate = moment(todayDate).format('YYYY-MM-DD');
                  const checkDate = dateInPast(
                    moment(item.availabilityDate).format('YYYY-MM-DD'),
                    currentDate,
                  );
                  const currentYear = moment(todayDate).format('YYYY');
                  const year = moment(item.availabilityDate).format('YYYY');
                  return (
                    <div className="floorFullDesc" key={item.id}>
                      <div className="floorWidth">
                        {`${item.sizeAreaSqFt.toLocaleString()} sq ft`}
                      </div>
                      <div className="floorWidth">
                        {checkDate
                          ? year === currentYear
                            ? `Available ${moment(item.availabilityDate).format(
                                'MMM YYYY',
                              )}`
                            : `Available  ${moment(
                                item.availabilityDate,
                              ).format('YYYY')}+`
                          : 'Available now'}
                      </div>
                      <div className="floorDesc floorDescWordBreak">
                        {item.description
                          ? item.description
                          : 'Unit description is not yet ready, please wait...'}
                      </div>

                      <div className="floorDesc">
                        {`Energy performance certificate: ${item.epc}`}
                      </div>
                      {item.wireCertified && (
                        <div className="floorDesc">
                          {`WiredScored certification: ${item.wireCertified}`}
                        </div>
                      )}
                      <div className="floorFeature">
                        <ul>
                          {item.features.map(feature => {
                            return (
                              <li key={feature.id}>
                                <span>
                                  <img
                                    src={
                                      (feature && feature.imageIcon) ||
                                      buttonCard1
                                    }
                                    alt="buttonCard1"
                                  />
                                </span>
                                <div className="featureText">
                                  {feature.name}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default BuildingUnitDetails;
