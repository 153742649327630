import { Breadcrumbs, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import backButton from '../../images/buttons-back.svg';
import backButtonWhite from '../../images/buttons-back-white.svg';

const useStyles = makeStyles(() => ({
  breadcrumbMain: {
    padding: '20px 25px',
    '& ol': {
      alignItems: 'center',
      '& li': {
        '& a': {
          fontSize: '13px',
          lineHeight: '17px',
          color: '#838384',
          fontFamily: 'Conv_AtlasGrotesk-Regular-App',
          textDecoration: 'none',
          textTransform: 'capitalize',
        },
        '&:first-child': {
          '& a': {
            display: 'flex',
            alignItems: 'center',
            '& img': {
              marginRight: '15px !important',
            },
          },
        },
      },
    },
  },
}));

const Breadcrunb = props => {
  const { breadCrumbs, colorChange, BuildingUnitDetails } = props;
  const classes = useStyles();
  // const history = useHistory();

  return (
    <div
      className={colorChange ? 'staffBreadcrumbMain' : classes.breadcrumbMain}
    >
      <Breadcrumbs aria-label="breadcrumb">
        {breadCrumbs.backToPath && <Link color="inherit" to={breadCrumbs.backToPath}>
          {!colorChange ? (
            <img src={backButton} alt="closeIcon" role="presentation" />
          ) : (
            <img
              src={backButtonWhite}
              alt="backButtonWhite"
              role="presentation"
            />
          )}
          Back
        </Link>}
        {BuildingUnitDetails ? (
          <Link color="inherit">{breadCrumbs.currentPage}</Link>
        ) : (
          <Link color="inherit" to={breadCrumbs.currentPagePath}>
            {breadCrumbs.currentPage}
          </Link>
        )}
      </Breadcrumbs>
    </div>
  );
};
export default Breadcrunb;
