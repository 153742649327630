import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const HtmlEditor = props => {
  const { descriptionValue } = props;
  const html = descriptionValue;
  return <div className="newsDescFull newsDescEditor ql-editor">{ReactHtmlParser(html)}</div>;
};

export default HtmlEditor;
