import * as actionTypes from '../utils/constants';

const initialState = {};
const tenantDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TENANT_DETAILS: {
      const newState = {
        ...state,
      };
      newState.tenantDetailsReducer = action.action;
      return newState;
    }
    default: {
      return state;
    }
  }
};

export default tenantDetailsReducer;
