import { GET_NEWSANNOUNCEMENTLIST } from '../utils/constants';

const getNewsAnnouncements = action => ({
  type: GET_NEWSANNOUNCEMENTLIST,
  action,
});

export const getNewsAnnouncement = newsAnnouncements => dispatch => {
  dispatch(getNewsAnnouncements(newsAnnouncements));
};
