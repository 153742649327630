import React, { useContext } from 'react';
import {
  Button,
  // LinearProgress,
  List,
  ListItem,
} from '@material-ui/core';
import '../../container/Registration/styles.css';
import logo from '../../images/logo.svg';
import { GlobalContext } from '../../contaxt/GlobalContext';

const Header = () => {
  const { tenantUserName, logOut } = useContext(GlobalContext);

  if (!(localStorage && localStorage.getItem('authorizationToken'))) {
    logOut();
  }

  return (
    <div className="tenantHomeHeader">
      <div className="pagecontainer">
        <div className="tenantHomeHeaderMain">
          <div className="tenantHomeHeaderWrap">
            <div className="tenantHomeHeaderLogo">
              <a href="/manage-staff-list">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <div className="tenantHomeHeaderNav">
              <List>
                <ListItem>
                  <Button
                    onClick={() => {
                      logOut();
                    }}
                  >
                    Sign out
                  </Button>
                </ListItem>
                <ListItem>{tenantUserName || ''}</ListItem>
              </List>
            </div>
          </div>
        </div>
      </div>
      {/* <LinearProgress color="secondary" /> */}
    </div>
  );
};
export default Header;
