/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import * as lodash from 'lodash';
import '../Registration/styles.css';
import './space.css';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import buildingLogo1 from '../../images/building-1.png';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import {
  getDerwentBuildingsAction,
  getBuildingsByVillageAction,
} from '../../Actions/getDerwentBuildingsAction';
import Footer from '../../components/Footer/Footer';
import { GlobalContext } from '../../contaxt/GlobalContext';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Space = () => {
  const { logOut } = useContext(GlobalContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [buildingData, setBuildingsData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [buildingGroupsList, setBuildingGroupsList] = useState([]);

  // Get all buildings.
  const getBuildings = buildingsPayload => {
    setIsLoading(true);
    ApiClient.apiPost(apiEndPoints.getBuildings, buildingsPayload)
      .then(data => {
        const builldingsData = data && data.data && data.data.data;
        setIsLoading(false);
        const buildingGroups = lodash.mapValues(
          lodash.groupBy(builldingsData, 'village'),
          buildingList => buildingList.map(building => building),
        );
        const buildingGroups2 = Object.entries(buildingGroups);
        dispatch(getBuildingsByVillageAction(buildingGroups2));
        // setBuildingGroupsList(buildingGroups2);
        dispatch(getDerwentBuildingsAction(builldingsData));
        setBuildingsData(data && data.data && data.data.data);
      })
      .catch(error => {
        if (error.response.status.toString() === '440') logOut();
        setIsLoading(false);
        const err = error;
        if (error.response.status.toString() !== '440')
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
      });
  };

  useEffect(() => {
    getBuildings({
      sortBy: 'name',
      sortOrder: 'asc',
      offset: 0,
      limit: 500,
    });
  }, []);

  // Remove duplicate villages.
  const uniqueVillages = Array.from(
    new Set(buildingData && buildingData.map(a => a.village)),
  ).map(id => {
    return buildingData.find(a => a.village === id);
  });

  return (
    <div className={classes.root}>
      <Header />
      <div className="tenantHomePage">
        <div className="tenantHomeWrap">
          <Sidenav selectedSpace />
          <div className="contactsMain">
            <div className="villageHeadingSticky">
              <div className="villageHeading">
                <h2>Derwent Villages</h2>
                <div className="viewAllVilg">
                  <Link to="/availablespaces">View All</Link>
                </div>
              </div>
              <div className="villgSubHead">
                <p>
                  In addition to our 5.5m square foot London portfolio, private
                  office space on flexible leases from 12 months is available
                  accross our buildings. Fixed rate inclusive of rent, service
                  charge, & business rates.
                </p>
              </div>
            </div>
            <div className="villageIn">
              <ul>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  uniqueVillages &&
                  uniqueVillages.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`/${
                            (item &&
                              item.village &&
                              item.village.replace(' ', '-').toLowerCase()) ||
                            item.village.toLowerCase()
                          }/buildings`}
                        >
                          <div className="villageLogo">
                            <img src={buildingLogo1} alt="buildingLogo1" />
                          </div>
                          <div className="villageName">
                            <span>{item.village}</span>
                          </div>
                        </Link>
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Space;
