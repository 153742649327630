import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import editLogo from '../../images/buttons-edit.svg';
import closeIcon from '../../images/buttons-tp-x.svg';
import { GlobalContext } from '../../contaxt/GlobalContext';

const ViewStaffDetails = props => {
  const { open, setOpen, tenantUserDetails } = props;
  const { rolePermission } = useContext(GlobalContext);
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="viewStaffPopup">
          <div className="popUpHeader">
            <div className="popupClose">
              <img
                src={closeIcon}
                alt="closeIcon"
                role="presentation"
                onClick={handleClose}
              />
            </div>
            {rolePermission && rolePermission.editTenantUser && (
              <div className="popupEdit">
                <img
                  src={editLogo}
                  alt="editLogo"
                  role="presentation"
                  onClick={() =>
                    history.push(`/edit-staff-user/${tenantUserDetails.id}`)
                  }
                />
              </div>
            )}
          </div>
          <div className="popUpBody">
            <div className="staffViewHead">
              <span
                className={`${
                  tenantUserDetails.status === 'Blocked' ? 'staffBlocked' : ''
                }`}
              >
                {tenantUserDetails.status}
              </span>
              <small>
                {tenantUserDetails.building && tenantUserDetails.building.name}
              </small>
            </div>
            <div className="staffViewDetails">
              <h2>{`${tenantUserDetails.firstName} ${tenantUserDetails.lastName}`}</h2>
              <ul>
                <li>{tenantUserDetails.email}</li>
                <li>{tenantUserDetails.role}</li>
                <li>
                  {tenantUserDetails.payToAccount
                    ? 'Pay To Account Access'
                    : ''}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default ViewStaffDetails;
