import React, { useContext, useEffect, useState } from 'react';
import '../Registration/styles.css';
import './contacts.css';
import {
  CircularProgress,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import emailIcon from '../../images/icons-email.svg';
import phoneIcon from '../../images/icons-phone.svg';
import contactIcon from '../../images/user-logo.png';
import dowmArw from '../../images/down-arw.svg';
import apiEndPoints from '../../helpers/apiEndPoints';
import ApiClient from '../../helpers/ApiClient';
import { GlobalContext } from '../../contaxt/GlobalContext';
import Footer from '../../components/Footer/Footer';

const useStyles = makeStyles(() => ({
  formSelectBox: {
    marginTop: '0 !important',
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      width: '100%',
      borderBottom: '1px solid #838384',
      fontSize: '15px',
      lineHeight: '20px',
      color: '#838384',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      background: 'transparent !important',
      padding: '0 0 2px 0',
      minWidth: '150px',
      '&:after': {
        position: 'absolute',
        content: '""',
        background: `url(${dowmArw}) no-repeat 95% center !important`,
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        height: '10px',
        width: '10px',
      },
      '& div': {
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
  },
}));

const Contacts = () => {
  const { tenantId, tenantDetailsData, logOut } = useContext(GlobalContext);
  const classes = useStyles();
  const [buildings, setBuildings] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [loading, setLoading] = useState([]);
  const [location, setLocation] = useState(
    tenantDetailsData &&
      tenantDetailsData.building &&
      tenantDetailsData.building.id.toString(),
  );
  const handleClick = buildingId => {
    const buildingDetails =
      buildings && buildings.filter(data => data.id === buildingId);
    setSelectedLocation(buildingDetails && buildingDetails[0].contacts);
  };

  useEffect(() => {
    setLoading(true);
    if (tenantDetailsData) {
      const buildingsPayload = {
        tenantId,
        sortBy: 'name',
        sortOrder: 'asc',
        offset: 0,
        limit: 500,
      };
      ApiClient.apiPost(apiEndPoints.getBuildings, buildingsPayload)

        .then(builldingsData => {
          const data =
            builldingsData && builldingsData.data && builldingsData.data.data;
          setBuildings(data);
          setLoading(false);
        })
        .catch(error => {
          if (error.response.status.toString() === '440') logOut();
          console.log(error, 'error');
          setLoading(false);
        });
      ApiClient.apiGet(
        `${apiEndPoints.getBuildingDetails}${
          tenantDetailsData &&
          tenantDetailsData.building &&
          tenantDetailsData.building.id
        }`,
      )
        .then(data => {
          const defaultBuilding = data && data.data && data.data.data;
          setSelectedLocation(defaultBuilding.contacts);
          setLoading(false);
        })
        .catch(error => {
          if (error.response.status.toString() === '440') logOut();
          setLoading(false);
          console.log(error, 'error');
        });
    }
  }, [tenantDetailsData]);

  return (
    <div className={classes.root}>
      <Header />
      <div className="tenantHomePage">
        <div className="tenantHomeWrap">
          <Sidenav selected />
          {loading ? (
            <CircularProgress color="inherit" />
          ) : (
            <div className="contactsMain">
              <div className="buildingSelectContact">
                <h2>Your Derwent Contacts for </h2>
                {buildings && buildings.length > 1 ? (
                  <FormControl className={classes.formControl}>
                    <Select
                      className={classes.formSelectBox}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      value={location.toString()}
                      onChange={e => {
                        setLocation(e.target.value);
                        handleClick(e.target.value);
                      }}
                    >
                      {buildings &&
                        buildings.map(item => (
                          <MenuItem
                            insetchildren="true"
                            checked={item && item.name}
                            key={item.id}
                            value={item.id}
                            className="multiSelectMenu"
                            primarytext={item.name}
                          >
                            {`${item.name} (${item.village})`}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : (
                  buildings &&
                  buildings.length > 0 && (
                    <h2>{`${buildings && buildings[0].name} (${
                      buildings && buildings[0].village
                    })`}</h2>
                  )
                )}
              </div>
              <div className="contactsIn">
                <ul>
                  {selectedLocation &&
                    selectedLocation.map(contactDetails => (
                      <li>
                        <div className="contactLogo">
                          <img src={contactIcon} alt="contactIcon" />
                        </div>
                        <div className="contactPeofile">
                          {contactDetails.name && (
                            <span>{contactDetails.name}</span>
                          )}
                          {contactDetails.role && (
                            <small>{contactDetails.role}</small>
                          )}
                        </div>
                        <h4>
                          “This is the dummy note, we are waiting for the actual
                          one”
                        </h4>
                        {contactDetails.email && (
                          <div className="contactEmail">
                            <img src={emailIcon} alt="emailIcon" />
                            <span>{contactDetails.email}</span>
                          </div>
                        )}
                        {contactDetails.mobile && (
                          <div className="contactEmail">
                            <img src={phoneIcon} alt="phoneIcon" />
                            <span>{contactDetails.mobile}</span>
                          </div>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Contacts;
