/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router';
import urlencode from 'urlencode';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import '../Registration/styles.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import newsLogo from '../../images/news-logo.svg';
import announcementLogo from '../../images/announcement-logo.svg';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import playIcon from '../../images/play-button.svg';
import HtmlEditor from '../../components/HtmlEditor';
import { compare } from '../../utils/dateFunctions';
import Footer from '../../components/Footer/Footer';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { authorOfNewsAndAnnouncements } from '../../utils/constants';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles(() => ({
  root: {},
}));

const newsDetailsPage = props => {
  const articleType =
    props &&
    props.location &&
    props.location.pathname &&
    props.location.pathname.includes('announcementdetails');
  const classes = useStyles();
  const [playVideo, setPlayVideo] = useState(false);
  const [detailedNews, setDetailedNews] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  // Scroll to top on the browser.
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [props && props.location && props.location.pathname]);
  const listOfNewsAnnouncements = useSelector(
    state =>
      state &&
      state.newsAnnouncementDataReducer &&
      state.newsAnnouncementDataReducer.newsAnnouncementDataReducer,
  );
  const todayDate = new Date().toISOString();
  const currentDate = moment(todayDate).format('DD/MM/YYYY');

  const listOfNews =
    listOfNewsAnnouncements &&
    listOfNewsAnnouncements.filter(
      item =>
        item.type === 'News' &&
        item.mediaType !== 'featured' &&
        item.id !== id &&
        compare(currentDate, moment(item.startDate).format('DD/MM/YYYY')) &&
        compare(currentDate, moment(item.endDate).format('DD/MM/YYYY')),
    );

  useEffect(() => {
    setLoading(true);
    ApiClient.apiGet(`${apiEndPoints.createArticale}/${id}`)
      .then(data => {
        setLoading(false);
        setDetailedNews([data && data.data && data.data.data]);
      })
      .catch(error => {
        setLoading(false);
        alert(error);
      });
  }, [id]);

  const listOfAnnouncements =
    listOfNewsAnnouncements &&
    listOfNewsAnnouncements.filter(
      item =>
        item.type === 'Announcement' &&
        item.id !== id &&
        compare(currentDate, moment(item.startDate).format('DD/MM/YYYY')) &&
        compare(currentDate, moment(item.endDate).format('DD/MM/YYYY')),
    );

  // const detailedNews1 =
  //   listOfNewsAnnouncements &&
  //   listOfNewsAnnouncements.filter(item => item.id === id);

  return (
    <div className={classes.root}>
      <Header />
      <div className="tenantHomePage">
        <div className="tenantHomeWrap">
          <Sidenav />
          <div className="tenantNews">
            <h2>{articleType ? 'Announcement' : 'News'}</h2>
            {loading ? (
              <CircularProgress />
            ) : (
              detailedNews &&
              detailedNews.map(news => {
                return (
                  <div className="newsBlock" key={news.id}>
                    <div className="newsAuthorDtls">
                      <div className="newsAuthor">
                        <img
                          src={articleType ? announcementLogo : newsLogo}
                          alt="newsLogo"
                        />
                        <span>
                          {news && news.author
                            ? news && news.author
                            : authorOfNewsAndAnnouncements}
                        </span>
                      </div>
                      <div className="newsDate">
                        {moment(news && news.startDate).format('D MMM  YYYY')}
                      </div>
                    </div>
                    <div className="newsTitle newsTitleInner">
                      <div>{news && news.title}</div>

                      {news && news.type === 'News' && (
                        <div className="NewsCategory">
                          {`${news.category.name}`}
                        </div>
                      )}
                    </div>
                    <div className="newsDescFull">
                      {((news && news.video) ||
                        (news &&
                          news.videoPreviewImage &&
                          news.videoPreviewImage.length > 0) ||
                        (news && news.newsImage && news.newsImage.length > 0) ||
                        (news &&
                          news.videoPreviewImage &&
                          news.videoPreviewImage.length > 0)) && (
                        <div className="newsDescFullImg">
                          {news && news.video && playVideo ? (
                            <video
                              src={news && news.video}
                              autoPlay
                              controls
                              preload="none"
                            />
                          ) : (
                            ((news && news.newsPreviewImage) ||
                              (news &&
                                news.newsImage &&
                                news.newsImage.length > 0) ||
                              (news &&
                                news.videoPreviewImage &&
                                news.videoPreviewImage.length > 0)) && (
                              <img
                                src={
                                  (news && news.newsPreviewImage) ||
                                  (news &&
                                    news.newsImage &&
                                    news.newsImage[0] &&
                                    news.newsImage[0].imageUrl) ||
                                  (news &&
                                    news.videoPreviewImage &&
                                    news.videoPreviewImage[0] &&
                                    news &&
                                    news.videoPreviewImage[0].imageUrl)
                                }
                                alt="descLogo"
                              />
                            )
                          )}
                          {news && news.video && !playVideo && (
                            <Button
                              className="imgPlayIcon"
                              onClick={() => {
                                setPlayVideo(true);
                              }}
                            >
                              <img src={playIcon} alt="playIcon" />
                            </Button>
                          )}
                        </div>
                      )}
                      <p>{news && news.descriptionPreview}</p>
                    </div>
                    <HtmlEditor descriptionValue={news && news.description} />
                  </div>
                );
              })
            )}
          </div>
          <div className="tenantPersonalInfo">
            <div className="tenantPersonalInfoSticky">
              <div className="cover-bar">&nbsp;</div>
              <div className="noticeBoardInfo">
                <h2>{articleType ? 'Notice Board' : 'More News'}</h2>
                {articleType
                  ? listOfAnnouncements &&
                    listOfAnnouncements.length > 0 &&
                    listOfAnnouncements &&
                    listOfAnnouncements.map(announce => {
                      const announcementTitle = urlencode(
                        announce && announce.title,
                      )
                        .trim()
                        .toLowerCase();
                      return (
                        <div
                          key={announce && announce.id}
                          className="noticeBlockWrap"
                        >
                          <Link
                            to={`/announcementdetails/${announcementTitle}/${
                              announce && announce.id
                            }`}
                          >
                            <div className="noticeBlock">
                              <div className="newsAuthorDtls">
                                <div className="newsAuthor">
                                  <img src={announcementLogo} alt="newsLogo" />
                                  <span>
                                    {listOfAnnouncements &&
                                    listOfAnnouncements.length > 0 &&
                                    announce &&
                                    announce.author
                                      ? announce.author
                                      : authorOfNewsAndAnnouncements}
                                  </span>
                                </div>
                                <div className="newsDate">
                                  {announce &&
                                    announce.startDate &&
                                    moment(
                                      announce && announce.startDate,
                                    ).format('D MMM  YYYY')}
                                </div>
                              </div>
                              <span className="newsTitle">
                                {announce && announce.title}
                              </span>
                              <div className="noticeBoardLogo">
                                <img
                                  src={
                                    announce &&
                                    announce.newsImage &&
                                    announce.newsImage[0] &&
                                    announce.newsImage[0].imageUrl
                                  }
                                  alt={
                                    announce &&
                                    announce.newsImage &&
                                    announce.newsImage[0] &&
                                    announce.newsImage[0].imageUrl
                                  }
                                />
                              </div>
                              <div className="newsDescFull">
                                <p>{announce && announce.descriptionPreview}</p>
                              </div>
                            </div>
                            <div className="borderLine">&nbsp;</div>
                          </Link>
                        </div>
                      );
                    })
                  : listOfNews &&
                    listOfNews.length > 0 &&
                    listOfNews &&
                    listOfNews.map(news => {
                      const newsTitle = urlencode(news && news.title)
                        .trim()
                        .toLowerCase();
                      return (
                        <div key={news.id} className="noticeBlockWrap">
                          <Link
                            to={`/newsdetails/${newsTitle}/${news && news.id}`}
                          >
                            <div className="noticeBlock">
                              <div className="newsAuthorDtls">
                                <div className="newsAuthor">
                                  <img src={newsLogo} alt="newsLogo" />
                                  <span>
                                    {listOfNews &&
                                    listOfNews.length > 0 &&
                                    news &&
                                    news.author
                                      ? news.author
                                      : authorOfNewsAndAnnouncements}
                                  </span>
                                </div>
                                <div className="newsDate">
                                  {news &&
                                    news.startDate &&
                                    moment(news && news.startDate).format(
                                      'D MMM  YYYY',
                                    )}
                                </div>
                              </div>
                              <span className="newsTitle">
                                {news && news.title}
                              </span>
                              <div className="newsDescFull">
                                <p>{news && news.descriptionPreview}</p>
                              </div>
                              {((news && news.newsPreviewImage) ||
                                (news &&
                                  news.newsImage &&
                                  news.newsImage.length > 0) ||
                                (news &&
                                  news.videoPreviewImage &&
                                  news.videoPreviewImage.length > 0)) && (
                                <span className="imgnewsTitle">
                                  <img
                                    src={
                                      (news && news.newsPreviewImage) ||
                                      (news &&
                                        news.newsImage &&
                                        news.newsImage[0] &&
                                        news.newsImage[0].imageUrl) ||
                                      (news &&
                                        news.videoPreviewImage &&
                                        news.videoPreviewImage[0] &&
                                        news &&
                                        news.videoPreviewImage[0].imageUrl)
                                    }
                                    alt="descLogo"
                                  />
                                </span>
                              )}
                            </div>
                            <div className="borderLine">&nbsp;</div>
                          </Link>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default newsDetailsPage;
