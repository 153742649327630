import { GET_TENANT_DETAILS } from '../utils/constants';

const getTenantUserDetails = action => ({
  type: GET_TENANT_DETAILS,
  action,
});

export const getTenantDetails = tenantDetails => dispatch => {
  dispatch(getTenantUserDetails(tenantDetails));
};
