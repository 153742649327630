/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
// We will use these things from the lib
// https://react-google-maps-api-docs.netlify.com/
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { GOOGLE_API_KEY } from '../../utils/config';

export const MapContainer = props => {
  const {
    myPlaces,
    centerProp,
    buildingVillage,
    city,
    addressLine1,
    postCode,
    spaceMap,
  } = props;
  // The things we need to track in state
  const [mapRef, setMapRef] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [center] = useState(centerProp);
  // const [zoom, setZoom] = useState(5);
  const [clickedLatLng, setClickedLatLng] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);

  // Load the Google maps scripts
  const { isLoaded } = useLoadScript({
    // Enter your own Google Maps API key
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  // The places I want to create markers for.
  // This could be a data-driven prop.

  // Iterate myPlaces to size, center, and zoom map to contain all markers
  const fitBounds = map => {
    const bounds = new window.google.maps.LatLngBounds();
    myPlaces &&
      myPlaces.map(place => {
        bounds.extend(place.pos);
        return place.id;
      });
    map.fitBounds(bounds);
  };
  const loadHandler = map => {
    // Store a reference to the google map instance in state
    setMapRef(map);
    // Fit map bounds to contain all markers
    fitBounds(map);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap(prevState => {
      return { ...prevState, [place.id]: marker };
    });
  };

  // if you want to center the selected Marker
  const markerClickHandler = (event, place) => {
    // Remember which place was clicked
    setSelectedPlace(place);
    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }
    setInfoOpen(true);
    // If you want to zoom in a little on marker click
    // if (zoom < 13) {
    //   setZoom(13);
    // }
    // if you want to center the selected Marker
    // setCenter(place.pos)
  };

  const renderMap = () => {
    return (
      <>
        <GoogleMap
          // onLoad={loadHandler}
          options={{ scrollwheel: false }}
          // Save the current center position in state
          // onCenterChanged={() =>
          //   setCenter(mapRef && mapRef.getCenter().toJSON())
          // }
          // Save the user's map click position
          onClick={e => setClickedLatLng(e.latLng.toJSON())}
          center={center}
          zoom={18}
          mapContainerStyle={{
            height: '70vh',
            width: '100%',
          }}
        >
          {myPlaces &&
            myPlaces.length > 0 &&
            myPlaces &&
            myPlaces.map(place => (
              <Marker
                key={place.id}
                position={place.pos}
                onLoad={marker => markerLoadHandler(marker, place)}
                onClick={event => markerClickHandler(event, place)}
                // Not required, but if you want a custom icon:
                // icon={mapIcon}
              />
            ))}

          {infoOpen && selectedPlace && (
            <InfoWindow
              anchor={markerMap[selectedPlace.id]}
              onCloseClick={() => setInfoOpen(false)}
            >
              <div>
                <h3>{selectedPlace.id}</h3>
                <div>{addressLine1}</div>
                <div>{city}</div>
                <div>{buildingVillage}</div>
                <div>{postCode}</div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
        {/* Our center position always in state */}
        {spaceMap && (
          <h3>{`${myPlaces[0].id}, ${buildingVillage}, ${postCode}`}</h3>
        )}
      </>
    );
  };

  return isLoaded ? renderMap() : null;
};
