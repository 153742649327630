import React, { useEffect, useState } from 'react';
import './TransportForLondon.css';

const TransportForLondon = () => {
  const [TFLDetails, setTFLDetails] = useState([]);

  useEffect(() => {
    fetch(
      'https://api.tfl.gov.uk/line/mode/tube,overground,dlr,tflrail,tram/status',
    )
      .then(response => response.json())
      .then(data => {
        setTFLDetails(data);
        console.log('data', data);
      })
      .catch(err => {
        console.log('error', err);
      });
  }, []);

  const TFLData =
    TFLDetails &&
    TFLDetails.map(data => {
      return (
        <tr>
          <td className={`${data.id}`}>{data.name}</td>
          {data &&
            data.lineStatuses &&
            data.lineStatuses.map(item => (
              <td className={`${data.id}`}>{item.statusSeverityDescription}</td>
            ))}
        </tr>
      );
    });

  return (
    <div>
      <table className="rainbowBoard">
        <tbody>{TFLData}</tbody>
      </table>
    </div>
  );
};
export default TransportForLondon;
