/* eslint-disable react/destructuring-assignment */
import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBuildingsData } from '../Actions/getBuildingsActions';
import {
  getBuildingsByVillageAction,
  getDerwentBuildingsAction,
} from '../Actions/getDerwentBuildingsAction';
import { getSpacesAction } from '../Actions/getSpacesAction';
import { getTenantDetails } from '../Actions/getTenantDetailsAction';
import { getNewsAnnouncement } from '../Actions/newsAnnouncementDataAction';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';
import { refreshTokenTimer } from '../utils/constants';

export const GlobalContext = createContext({});

const TenantPortalContextProvider = props => {
  const dispatch = useDispatch();
  const tenantDetailsData = useSelector(
    state =>
      state &&
      state.tenantDetailsReducer &&
      state.tenantDetailsReducer.tenantDetailsReducer,
  );
  const buildingsData = useSelector(
    state =>
      state &&
      state.buildingsReducer &&
      state.buildingsReducer.buildingsReducer,
  );
  const [buildingDataList, setBuildingDataList] = useState(buildingsData || []);
  const [tenantId, setTenantId] = useState(
    (tenantDetailsData &&
      tenantDetailsData.tenant &&
      tenantDetailsData.tenant.id) ||
      '',
  );
  const [tenantUserName, setTenantUserName] = useState(
    `${tenantDetailsData && tenantDetailsData.firstName}${' '}${
      tenantDetailsData && tenantDetailsData.lastName
    }` || '',
  );
  const [isPatyToAcc, setIsPatyToAcc] = useState(
    (tenantDetailsData && tenantDetailsData.payToAccount) || false,
  );
  const [buildingId, setBuildingId] = useState('');
  const [buildingVillage, setBuildingVillage] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [tenantUserSuccessmsg, setTenantUserSuccesssMsg] = useState('');
  const tenantData = (tenantDetailsData && tenantDetailsData.tenant) || '';
  const [permissionsStatus, setPermissionsStatus] = useState(false);
  const [rolePermission, setRolePermission] = useState(false);
  const [tenantUserRole, setTenantUserRole] = useState(tenantDetailsData?.role);
  const refreshToken = JSON.parse(localStorage.getItem('refresh_token'));

  const fetchData = async () => {
    await ApiClient.apiPost(apiEndPoints.getRefreshToken, {
      refresh_token: refreshToken,
    })
      .then(data => {
        const generatedToken = data && data.data && data.data.data;
        localStorage.setItem(
          'authorizationToken',
          JSON.stringify(generatedToken.access_token),
        );
        localStorage.setItem(
          'refresh_token',
          JSON.stringify(generatedToken.refresh_token),
        );
        console.log('data', generatedToken);
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, refreshTokenTimer);
    console.log('interval', interval);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (permissionsStatus) {
      setRolePermission(
        localStorage.getItem('rolePermission') &&
          JSON.parse(localStorage.getItem('rolePermission'))[0],
      );
    }
  }, [permissionsStatus]);

  useEffect(() => {
    setRolePermission(
      localStorage.getItem('rolePermission') &&
        JSON.parse(localStorage.getItem('rolePermission'))[0],
    );
    console.log(rolePermission, 'rolePermission');
  }, []);
  useEffect(() => {
    setTenantUserRole(tenantDetailsData?.role);
  }, [tenantDetailsData]);

  const logOut = () => {
    dispatch(getTenantDetails(undefined));
    dispatch(getBuildingsData(undefined));
    dispatch(getNewsAnnouncement(undefined));
    dispatch(getBuildingsByVillageAction(undefined));
    dispatch(getDerwentBuildingsAction(undefined));
    dispatch(getSpacesAction(undefined));
    setTenantUserName('');
    localStorage.removeItem('authorizationToken');
    localStorage.removeItem('emailValue');
    localStorage.clear();
    // history.push('/');
    window.location.href = '/';
  };

  return (
    <GlobalContext.Provider
      value={{
        tenantId,
        setTenantId,
        tenantUserName,
        setTenantUserName,
        buildingId,
        setBuildingId,
        buildingName,
        setBuildingName,
        buildingVillage,
        setBuildingVillage,
        isPatyToAcc,
        setIsPatyToAcc,
        buildingDataList,
        setBuildingDataList,
        tenantData,
        tenantUserSuccessmsg,
        setTenantUserSuccesssMsg,
        tenantDetailsData,
        setPermissionsStatus,
        rolePermission,
        logOut,
        tenantUserRole,
        setTenantUserRole,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default TenantPortalContextProvider;
