/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  makeStyles,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import './styles.css';
import { useHistory, useParams } from 'react-router-dom';
import { forgotSchema } from '../../utils/validation';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';

const useStyles = makeStyles(() => ({
  root: {
    '& button': {
      fontSize: '16px',
      lineHeight: '47px',
      height: '47px',
      textAlign: 'center',
      color: '#fff',
      background: '#000',
      fontFamily: 'Conv_AtlasGrotesk-Medium-App',
      textTransform: 'inherit',
      width: '100%',
      borderRadius: '0',
      padding: '0',
      '&:hover': {
        background: '#000',
        boxShadow: 'none',
      },
    },
  },
}));

const ForgotPassword = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMsgs, setErrorMsgs] = useState('');
  const [success, setSuccess] = useState(false);
  const [emailLink, setEmailLink] = useState('');
  const [successMsg, setSuccessMsg] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      ApiClient.apiGet(`${apiEndPoints.resetPassword}/reset?sha=${id}`)
        .then(() => {
          setLoading(false);
        })
        .catch(error => {
          const err = error;
          setLoading(false);
          setEmailLink(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
          setSuccess(true);
        });
    }
  }, []);

  const handleClick = () => {
    setSubmit(true);
  };
  useEffect(() => {
    forgotSchema
      .validate({
        password,
      })
      .then(() => {
        if (submit && confirmPassword === password) {
          setLoading(true);
          const payload = {
            password,
          };
          ApiClient.apiPost(
            `${apiEndPoints.resetPassword}/reset?sha=${id}`,
            payload,
          )
            .then(() => {
              setLoading(false);
              setSubmit(false);
              setSuccess(true);
              setSuccessMsg(true);
            })
            .catch(error => {
              setLoading(false);
              setSubmit(false);
              console.log(error);
            });
        }
      })
      .catch(error => {
        if (submit) {
          setErrorMsgs(error);
          console.log('error', error);
          setSubmit(false);
        }
      });
  }, [submit, confirmPassword, password]);

  useEffect(() => {
    setTimeout(() => {
      setSuccessMsg(false);
    }, 4000);
  }, [successMsg]);

  const errorType = errorMsgs && errorMsgs.type;
  const errorFieldName = errorMsgs && errorMsgs.path;
  const errorFieldValue = errorMsgs && errorMsgs.value;
  return (
    <div className="tenantPortalApp resetTenantPortalApp">
      <div className={success ? 'tpFormBox readyToGo' : 'tpFormBox'}>
        {success && !emailLink && (
          <>
            <h3>Great, you&apos;re all ready to go.</h3>
            <div className="fieldvalidation">
              Click the button below to sign in.
            </div>
            <ul>
              <li>
                <div className={classes.root}>
                  <Button
                    onClick={() => {
                      history.push('/');
                    }}
                    variant="contained"
                  >
                    Sign in
                  </Button>
                </div>
              </li>
            </ul>
          </>
        )}
        {emailLink && success && (
          <>
            <h3>Seems like your link expired, please try again.</h3>
            <div className="fieldvalidation">
              Click the button below to sign in.
            </div>
            <ul>
              <li>
                <div className={classes.root}>
                  <Button
                    onClick={() => {
                      history.push('/');
                    }}
                    variant="contained"
                  >
                    Sign in
                  </Button>
                </div>
              </li>
            </ul>
          </>
        )}
        {loading ? (
          <CircularProgress />
        ) : (
          !success && (
            <>
              <h1>Set your password.</h1>
              {confirmPassword !== password &&
                submit &&
                password.length >= 8 && (
                  <div className="errorLocked">
                    Passwords do not match, please try again.
                  </div>
                )}
              <form className="form">
                <ul>
                  <li>
                    <TextField
                      labelText="Password"
                      id="Password"
                      label="Password"
                      value={password}
                      onChange={e => {
                        setPassword(e.target.value);
                        setErrorMsgs('');
                        setSubmit(false);
                      }}
                      type="Password"
                    />
                    {((errorType === 'required' &&
                      errorFieldName === 'password') ||
                      (errorType === 'matches' &&
                        errorFieldName === 'password') ||
                      (errorType === 'min' && errorFieldName === 'password') ||
                      (errorFieldValue && errorFieldValue.password === '')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={
                          (password === '' && 'Please enter password.') ||
                          (password.length < 8 &&
                            'Password must be at least 8 characters') ||
                          (errorType === 'matches' &&
                            'Password must meet the below criteria')
                        }
                      >
                        {(password === '' && 'Please enter password.') ||
                          (password.length < 8 &&
                            'Password must be at least 8 characters') ||
                          (errorType === 'matches' &&
                            'Password must meet the below criteria')}
                      </FormHelperText>
                    )}
                  </li>
                  <li>
                    <TextField
                      labelText="Confirm password"
                      id="confirmPassword"
                      label="Confirm password"
                      value={confirmPassword}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      onChange={e => {
                        setConfirmPassword(e.target.value);
                        setErrorMsgs('');
                        setSubmit(false);
                      }}
                      type="password"
                    />
                    {((errorType === 'required' &&
                      errorFieldName === 'confirmPassword') ||
                      (errorType === 'min' &&
                        errorFieldName === 'confirmPassword') ||
                      (errorFieldValue &&
                        errorFieldValue.confirmPassword === '')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={
                          (confirmPassword === '' &&
                            'Please enter password.') ||
                          (confirmPassword.length < 8 &&
                            'Password must be at least 8 characters')
                        }
                      >
                        {(confirmPassword === '' && 'Please enter password.') ||
                          (confirmPassword.length < 8 &&
                            'Password must be at least 8 characters')}
                      </FormHelperText>
                    )}
                  </li>
                  <li>
                    <div className="fieldvalidation">
                      Please enter a password with a minimum of 8 characters
                      including 1 upper case letter, 1 lower case letter, 1
                      number and 1 special case character.
                    </div>
                  </li>
                  <li>
                    <div className={classes.root}>
                      <Button variant="contained" onClick={handleClick}>
                        {loading ? (
                          <CircularProgress size={22} color="secondary" />
                        ) : (
                          'Set Password'
                        )}
                      </Button>
                    </div>
                  </li>
                </ul>
              </form>
            </>
          )
        )}
      </div>
    </div>
  );
};
export default ForgotPassword;
