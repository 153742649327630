import { baseUrl } from '../utils/config';

const apiEndPoints = {
  login: `${baseUrl}/v1/login?`,
  reset: `${baseUrl}/v1/reset?`,
  getTenantUser: `${baseUrl}/v1/tenants/users/`,
  getTenantsUsersList: `${baseUrl}/v1/tenants/users/search`,
  resetPassword: `${baseUrl}/v1`,
  getNewsAnnouncementsList: `${baseUrl}/v1/articles/search`,
  getBuildings: `${baseUrl}/v1/buildings/search`,
  getBuildingDetails: `${baseUrl}/v1/buildings/`,
  getTenantsUserId: `${baseUrl}/v1/tenants/users/`,
  createOrUpdateTenantUser: `${baseUrl}/v1/tenants/users`,
  getTenants: `${baseUrl}/v1/tenants`,
  getSpacesList: `${baseUrl}/v1/spaces/search`,
  getNearbyStations: `${baseUrl}/v1/stations`,
  getRolePermission: `${baseUrl}/v1/role/permissions`,
  getArrears: `${baseUrl}/v1/Tenant`,
  getTenantDetails: `${baseUrl}/v1/tenants`,
  createArticale: `${baseUrl}/v1/articles`,
  getRefreshToken: `${baseUrl}/v1/refresh`,
};
export default apiEndPoints;
