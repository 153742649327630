export const GET_NEWSANNOUNCEMENTLIST = 'GET_NEWSANNOUNCEMENTLIST';
export const GET_TENANT_DETAILS = 'GET_TENANT_DETAILS';
export const GET_BUILDINGS = 'GET_BUILDINGS';
export const GET_DERWENT_BUIDLINGS = 'GET_DERWENT_BUIDLINGS';
export const GET_SPACE_DETAILS = 'GET_SPACE_DETAILS';
export const GET_DERWENT_VILLAGE_BUIDLINGS = 'GET_DERWENT_VILLAGE_BUIDLINGS';
export const GET_SPACE_DETAILS_COUNT = 'GET_SPACE_DETAILS_COUNT';

export const roleValues = ['Super Administrator'];
export const roleUpdateValues = [...roleValues, 'Employee'];
export const jobTiitles = [
  'Contractor',
  'Freelancer',
  'Employee',
  'Manager',
  'Executive',
  'Director',
  'Founder',
];
export const nearByStationsRadius = 1500;
export const priceLocalFormate = data =>
  data.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
export const deskOptions = ['1-10', '11-20', '20+'];
// If there is no other for News and announcement then will display this one.
export const authorOfNewsAndAnnouncements = 'Derwent London';
export const refreshTokenTimer = 1.8e6;
export const tenantUserRoles={
  superAdministrator:'Super Administrator',
  employee:'Employee',
}
