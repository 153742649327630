/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Link,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import './styles.css';
import validator from 'validator';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { loginSchema } from '../../utils/validation';

const useStyles = makeStyles(() => ({
  root: {
    '& button': {
      fontSize: '16px',
      lineHeight: '47px',
      height: '47px',
      textAlign: 'center',
      color: '#fff',
      background: '#000',
      fontFamily: 'Conv_AtlasGrotesk-Medium-App',
      textTransform: 'inherit',
      width: '100%',
      borderRadius: '0',
      padding: '0',
      '&:hover': {
        background: '#000',
        boxShadow: 'none',
      },
    },
    formInputtBox: {
      width: '100%',
      '& > div': {
        width: '100%',
        marginTop: '0 !important',
        borderBottom: '1px solid #838384',
        paddingRight: '35px',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#444444',
        fontFamily: 'Conv_HelveticaNeue-Regular',
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
        '&  > div': {},
      },
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState('');
  const [forget, setForget] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [loadingToggle] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [checked, setChecked] = useState(false);
  const handleClickCheck = () => setChecked(!checked);

  const validateEmail = e => {
    setEmail(e.target.value);
    if (validator.isEmail(e.target.value)) {
      setEmailError('');
    } else {
      setEmailError(e.target.value && 'Please enter a valid email.');
    }
  };

  useEffect(() => {
    loginSchema
      .validate({
        email,
        password,
      })
      .then(() => {
        if (submit && password.length >= 8) {
          setLoading(true);
          const payload = {
            email,
            password,
          };
          ApiClient.apiPost(apiEndPoints.login, payload)
            .then(data => {
              const generatedToken = data && data.data && data.data.data;
              localStorage.setItem(
                'authorizationToken',
                JSON.stringify(generatedToken.access_token),
              );
              localStorage.setItem(
                'refresh_token',
                JSON.stringify(generatedToken.refresh_token),
              );
              history.push('/manage-staff-list');
              setLoading(false);
              setSubmit(false);
            })
            .catch(err => {
              setLoading(false);
              setLoginSuccess(true);
              setErrorMsg(
                err &&
                  err.response &&
                  err.response.data &&
                  err.response.data.message,
              );
              setSubmit(false);
              // console.log(error);
            });
        }
      })
      .catch(error => {
        // console.log(error, 'error');
        if (submit) {
          setErrorMsgs(error);
          setSubmit(false);
        }
      });
  }, [submit, email, password]);

  const handleClick = () => {
    checked ? setChecked(!checked) : setChecked(false);
    setForget(false);
    setPassword('');
    setEmail('');
    setErrorMsgs('');
    setErrorMsg('');
  };

  const errorType = errorMsgs && errorMsgs.type;
  const errorFieldName = errorMsgs && errorMsgs.path;
  const errorFieldValue = errorMsgs && errorMsgs.value;

  return (
    <div
      className={
        forget ? 'tenantPortalApp tenantPortalforgotApp' : 'tenantPortalApp'
      }
    >
      <div className="tpFormBox">
        {loadingToggle ? (
          <CircularProgress />
        ) : (
          forget && (
            <>
              <h1>Forgotten your password.</h1>
              <div className="fieldvalidationMsg">
                Please reset your password via the DL/ App
              </div>
              <ul>
                <li>
                  <div className={classes.root}>
                    <Button variant="contained" onClick={handleClick}>
                      Sign in
                    </Button>
                  </div>
                </li>
              </ul>
            </>
          )
        )}
        <>
          {!forget && (
            <>
              {loading ? (
                <div className="signingInWaitMain">
                  <p className="signingInWait">Signing you in...</p>
                  <CircularProgress
                    color="#000"
                    className="crcularprogressSignin"
                  />
                </div>
              ) : (
                <>
                  <h1>
                    {forget
                      ? !loadingToggle && 'Forgotten your password.'
                      : !loadingToggle && 'Sign into your account.'}
                  </h1>

                  {loginSuccess && (
                    <div className="errorLocked">{errorMsg}</div>
                  )}
                  {!loadingToggle && (
                    <form className="form">
                      <ul>
                        {!forget && (
                          <>
                            {/* email field */}
                            <li>
                              <TextField
                                className={classes.formInputtBox}
                                label="Email"
                                labelText="Email"
                                id="email"
                                value={email}
                                onChange={e => {
                                  const re = /^[A-Za-z0-9.@]+$/;
                                  if (
                                    e.target.value === '' ||
                                    re.test(e.target.value)
                                  ) {
                                    validateEmail(e);
                                    setLoginSuccess(false);
                                  }
                                }}
                                type="text"
                              />
                              {((errorType === 'required' &&
                                errorFieldName === 'email') ||
                                errorType === 'email' ||
                                (errorFieldValue &&
                                  errorFieldValue.email === '')) && (
                                <FormHelperText
                                  className={classes.errorMsg}
                                  error={emailError}
                                >
                                  {emailError}
                                </FormHelperText>
                              )}
                            </li>
                            {/* password field */}
                            <li>
                              <TextField
                                className={classes.formInputtBox}
                                label="Password"
                                labelText="Password"
                                id="password"
                                value={password}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                onChange={e => {
                                  setPassword(e.target.value);
                                }}
                                type="password"
                              />
                              {((errorType === 'required' &&
                                errorFieldName === 'password') ||
                                (errorType === 'min' &&
                                  errorFieldName === 'password') ||
                                (errorFieldValue &&
                                  errorFieldValue.password === '')) && (
                                <FormHelperText
                                  className={classes.errorMsg}
                                  error={
                                    (password === '' &&
                                      'Please enter password.') ||
                                    (password.length < 8 &&
                                      'Password must be at least 8 characters')
                                  }
                                >
                                  {(password === '' &&
                                    'Please enter password.') ||
                                    (password.length < 8 &&
                                      'Password must be at least 8 characters')}
                                </FormHelperText>
                              )}
                            </li>
                            {/* checkbox field */}
                            <li className="ppCheckboxList">
                              <div className="ppCheckboxMain">
                                <div className="ppCheckbox">
                                  <Checkbox
                                    id="pPolicy"
                                    onClick={handleClickCheck}
                                    checked={checked}
                                    name="checkedB"
                                    color="primary"
                                  />
                                </div>
                                <label>
                                  By signing in to your account, you agree to
                                  our{' '}
                                  <Link
                                    onClick={() =>
                                      window.open(
                                        'https://www.derwentlondon.com/texts/app-terms-conditions',
                                        '_blank',
                                      )
                                    }
                                  >
                                    terms and conditions
                                  </Link>{' '}
                                  and{' '}
                                  <Link
                                    onClick={() =>
                                      window.open(
                                        'https://www.derwentlondon.com/texts/app-privacy-policy',
                                        '_blank',
                                      )
                                    }
                                  >
                                    privacy policy
                                  </Link>
                                  .
                                </label>
                              </div>
                            </li>
                            {/* signin button */}
                            <li>
                              <div className={classes.root}>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    if (!forget) {
                                      setSubmit(true);
                                    }
                                  }}
                                  disabled={
                                    !email ||
                                    (!password && !forget) ||
                                    (!checked && !forget)
                                  }
                                >
                                  Sign in
                                </Button>
                              </div>
                            </li>
                            {/* forgot button */}
                            <li className="forgotPassword">
                              <Button
                                variant="contained"
                                onClick={() => {
                                  setForget(true);
                                }}
                              >
                                Forgot password
                              </Button>
                            </li>
                          </>
                        )}
                      </ul>
                    </form>
                  )}
                </>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};
export default Login;
