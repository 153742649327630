/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { CircularProgress, Divider, makeStyles } from '@material-ui/core';
import '../Registration/styles.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import urlencode from 'urlencode';
import newsLogo from '../../images/news-logo.svg';
import announcementLogo from '../../images/announcement-logo.svg';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import apiEndPoints from '../../helpers/apiEndPoints';
import ApiClient from '../../helpers/ApiClient';
import { getNewsAnnouncement } from '../../Actions/newsAnnouncementDataAction';
import { GlobalContext } from '../../contaxt/GlobalContext';
import { MapContainer } from '../../components/Googlemap/MapContainer';
import { getTenantDetails } from '../../Actions/getTenantDetailsAction';
import VideoImage from '../../components/VideoImage/VideoImage';
import { compare } from '../../utils/dateFunctions';
import TransportForLondon from '../../components/TFL/TransportForLondon';
import Footer from '../../components/Footer/Footer';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import { authorOfNewsAndAnnouncements } from '../../utils/constants';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles(() => ({
  root: {},
}));

const HomePage = () => {
  const {
    buildingId,
    setTenantUserName,
    setTenantId,
    setBuildingId,
    setIsPatyToAcc,
    setPermissionsStatus,
    logOut,
  } = useContext(GlobalContext);

  if (!(localStorage && localStorage.getItem('authorizationToken'))) {
    logOut();
  }
  const classes = useStyles();
  const dispatch = useDispatch();
  const [listOfNewsAnnouncements, setListOfNewsAnnouncements] = useState([]);
  const [readMore, setReadMore] = useState(false);
  const [myPlaces, setMyplaces] = useState();
  const [center, setCenter] = useState();
  const [buildingVillage, setBuildingVillage] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [buildingImage, setBuildingImage] = useState([]);
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [county, setCounty] = useState('');
  const [city, setCity] = useState('');
  const [postCode, setPostCode] = useState('');
  const [tenantToggle, setTenantToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const decoded = jwt_decode(localStorage.getItem('authorizationToken'));
  const todaysDate = `${moment
    .utc(new Date())
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)}`;

  // get getRolePermission API Call
  const getRolePermission = () => {
    ApiClient.apiGet(apiEndPoints.getRolePermission)
      .then(data => {
        const permissionData = [data.data.data];
        const myHour = new Date();
        myHour.setHours(myHour.getHours() + 24);
        permissionData.push(myHour);
        localStorage.setItem('rolePermission', JSON.stringify(permissionData));

        setPermissionsStatus(true);
      })
      .catch(error => {
        if (error.response.status.toString() === '440') logOut();
        console.log(error, 'error');
      });
    // }
  };
  function checkExpiration() {
    const values = JSON.parse(localStorage.getItem('rolePermission'));
    if (values && values[1] < new Date()) {
      localStorage.removeItem('rolePermission');
    }
  }

  function myFunction() {
    const myinterval = 15 * 60 * 1000; // 15 min interval
    // eslint-disable-next-line func-names
    setInterval(function () {
      checkExpiration();
    }, myinterval);
  }

  myFunction();
  useEffect(() => {
    if (localStorage.getItem('rolePermission') === null) {
      getRolePermission();
    } else {
      setPermissionsStatus(true);
      // logOut();
    }
  }, []);

  useEffect(() => {
    if (decoded) {
      ApiClient.apiGet(`${apiEndPoints.getTenantUser}${decoded && decoded.oid}`)
        .then(data => {
          setTenantToggle(!tenantToggle);
          const tenantDetails = data && data.data && data.data.data;
          dispatch(getTenantDetails(tenantDetails));
          setTenantUserName(
            `${tenantDetails && tenantDetails.firstName}${' '}${
              tenantDetails && tenantDetails.lastName
            }`,
          );
          setIsPatyToAcc(tenantDetails && tenantDetails.payToAccount);
          setTenantId(
            tenantDetails && tenantDetails.tenant && tenantDetails.tenant.id,
          );
          setBuildingId(
            tenantDetails &&
              tenantDetails.building &&
              tenantDetails.building.id,
          );
          setBuildingName(
            tenantDetails &&
              tenantDetails.building &&
              tenantDetails.building.name,
          );
          setBuildingVillage(
            tenantDetails &&
              tenantDetails.building &&
              tenantDetails.building.village,
          );
        })
        .catch(error => {
          if (error.response.status.toString() === '440') logOut();
          const err = error;
          if (error.response.status.toString() !== '440')
            alert(
              err &&
                err.response &&
                err.response.data &&
                err.response.data.message,
            );
        });
    }
  }, []);

  const getNewsAnnouncementListAction = payload => {
    setIsLoading(true);
    ApiClient.apiPost(apiEndPoints.getNewsAnnouncementsList, payload)
      .then(data => {
        setIsLoading(false);
        dispatch(getNewsAnnouncement(data && data.data && data.data.data));
        setListOfNewsAnnouncements(data && data.data && data.data.data);
      })
      .catch(error => {
        if (error.response.status.toString() === '440') logOut();
        setIsLoading(false);
        const err = error;
        if (error.response.status.toString() !== '440')
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
      });
  };

  // Get the Building details by id.
  const getBuildingDetails = id => {
    setIsLoading(true);
    ApiClient.apiGet(`${apiEndPoints.getBuildingDetails}${id}`)
      .then(data => {
        setIsLoading(false);
        const placeData = data && data.data && data.data.data;
        setBuildingName(placeData && placeData.name);
        setBuildingVillage(placeData && placeData.village);
        setAddressLine1(placeData && placeData.addressLine1);
        setAddressLine2(placeData && placeData.addressLine2);
        setCounty(placeData && placeData.county);
        setCity(placeData && placeData.city);
        setPostCode(placeData && placeData.postcode);
        setBuildingImage(
          placeData && placeData.images[0] && placeData.images[0].image,
        );
        setCenter({
          lat: placeData && placeData.latitude,
          lng: placeData && placeData.longitude,
        });
        setMyplaces([
          {
            id: placeData && placeData.name,
            pos: {
              lat: placeData && placeData.latitude,
              lng: placeData && placeData.longitude,
            },
          },
        ]);
      })
      .catch(error => {
        if (error.response.status.toString() === '440') logOut();
        setIsLoading(false);
        const err = error;
        if (error.response.status.toString() !== '440')
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
      });
  };

  useEffect(() => {
    if (buildingId) {
      getNewsAnnouncementListAction({
        offset: 0,
        limit: 100,
        buildings: [buildingId],
        sortBy: 'startDate',
        sortOrder: 'desc',
        platform: ['TenantPortal', 'All'],
        todayDate: moment(todaysDate).toISOString(),
      });
      getBuildingDetails(buildingId);
    }
  }, [buildingId]);

  const todayDate = new Date().toISOString();
  const currentDate = moment(todayDate).format('DD/MM/YYYY');

  const listOfNews =
    listOfNewsAnnouncements &&
    listOfNewsAnnouncements.filter(
      item =>
        item.type === 'News' &&
        item.mediaType !== 'featured' &&
        compare(currentDate, moment(item.startDate).format('DD/MM/YYYY')) &&
        compare(currentDate, moment(item.endDate).format('DD/MM/YYYY')),
    );

  const listOfAnnouncements =
    listOfNewsAnnouncements &&
    listOfNewsAnnouncements.filter(
      item =>
        item.type === 'Announcement' &&
        compare(currentDate, moment(item.startDate).format('DD/MM/YYYY')) &&
        compare(currentDate, moment(item.endDate).format('DD/MM/YYYY')),
    );

  const newArray = [];
  const someAnnouncements =
    listOfAnnouncements && listOfAnnouncements.length > 2
      ? listOfAnnouncements.map((item, index) => {
          if (index <= 1) {
            newArray.push(item);
          }
        })
      : listOfAnnouncements;

  let removeUndefineds = someAnnouncements.filter(
    element => element !== undefined,
  );

  if (removeUndefineds && removeUndefineds.length === 0) {
    removeUndefineds = newArray;
  }

  const linkName = readMore ? 'See less…' : 'See more…';

  return (
    <div className={classes.root}>
      <Header />
      <div className="tenantHomePage">
        <div className="tenantHomeWrap">
          <Sidenav />
          <div className="tenantNews">
            <h2>Discover Derwent</h2>
            {isLoading ? (
              <div style={{ margin: '35% 35% 35% 35%' }}>
                <CircularProgress />
              </div>
            ) : (
              listOfNews &&
              listOfNews.map(news => {
                const newsTitle = urlencode(news && news.title)
                  .trim()
                  .toLowerCase();
                return (
                  <div className="newsBlock" key={news.id}>
                    <div className="newsAuthorDtls">
                      <div className="newsAuthor">
                        <img src={newsLogo} alt="newsLogo" />
                        <span>
                          {news && news.author
                            ? news && news.author
                            : authorOfNewsAndAnnouncements}
                        </span>
                      </div>
                      <div className="newsDate">
                        {moment(news && news.startDate).format('D MMM  YYYY')}
                      </div>
                    </div>
                    <Link
                      to={`/newsdetails/${newsTitle}/${news && news.id}`}
                      className="newsTitle"
                    >
                      {news && news.title}
                    </Link>
                    <div className="newsDescFull">
                      {((news && news.video) ||
                        (news && news.newsPreviewImage) ||
                        (news && news.newsImage && news.newsImage.length > 0) ||
                        (news &&
                          news.videoPreviewImage &&
                          news.videoPreviewImage.length > 0)) && (
                        <div className="newsDescFullImg">
                          <VideoImage news={news} />
                        </div>
                      )}
                      <p>{news && news.descriptionPreview}</p>
                    </div>
                    <div className="borderLine">&nbsp;</div>
                  </div>
                );
              })
            )}
            {!isLoading && listOfNews && listOfNews.length === 0 && (
              <NoResultsFound />
            )}
          </div>
          <div className="tenantPersonalInfo">
            <div className="tenantPersonalInfoSticky">
              <div className="cover-bar">&nbsp;</div>
              <div className="noticeBoardInfo">
                <h2>Notice Board</h2>
                {!readMore
                  ? listOfAnnouncements &&
                    listOfAnnouncements.length > 0 &&
                    removeUndefineds &&
                    removeUndefineds.map((announcement, index) => {
                      const announcementTitle = urlencode(
                        announcement && announcement.title,
                      )
                        .trim()
                        .toLowerCase();
                      return (
                        <div key={announcement.id} className="noticeBlockWrap">
                          <Link
                            to={`/announcementdetails/${announcementTitle}/${
                              announcement && announcement.id
                            }`}
                          >
                            <div className="noticeBlock">
                              <div className="newsAuthorDtls">
                                <div className="newsAuthor">
                                  <img
                                    src={announcementLogo}
                                    alt="announcementLogo"
                                  />
                                  <span>
                                    {removeUndefineds &&
                                    removeUndefineds.length > 0 &&
                                    announcement &&
                                    announcement.author
                                      ? announcement.author
                                      : authorOfNewsAndAnnouncements}
                                  </span>
                                </div>
                                <div className="newsDate">
                                  {announcement &&
                                    announcement.startDate &&
                                    moment(
                                      announcement && announcement.startDate,
                                    ).format('D MMM  YYYY')}
                                </div>
                              </div>
                              <span className="newsTitle">
                                {announcement && announcement.title}
                              </span>
                              <div className="noticeBoardLogo">
                                <img
                                  src={
                                    announcement &&
                                    announcement.newsImage &&
                                    announcement.newsImage[0] &&
                                    announcement.newsImage[0].imageUrl
                                  }
                                  alt={
                                    announcement &&
                                    announcement.newsImage &&
                                    announcement.newsImage[0] &&
                                    announcement.newsImage[0].imageUrl
                                  }
                                />
                              </div>
                              <div className="newsDescFull">
                                <p>
                                  {announcement &&
                                    announcement.descriptionPreview}
                                </p>
                              </div>
                            </div>
                            {index < 1 && (
                              <div className="borderLine">&nbsp;</div>
                            )}
                          </Link>
                        </div>
                      );
                    })
                  : listOfAnnouncements &&
                    listOfAnnouncements.map((announcement, index) => {
                      const announcementTitle = urlencode(
                        announcement && announcement.title,
                      )
                        .trim()
                        .toLowerCase();
                      return (
                        <div key={announcement.id} className="noticeBlockWrap">
                          <Link
                            to={`/announcementdetails/${announcementTitle}/${
                              announcement && announcement.id
                            }`}
                          >
                            <div
                              className="noticeBlock"
                              key={announcement && announcement.id}
                            >
                              <div className="newsAuthorDtls">
                                <div className="newsAuthor">
                                  <img
                                    src={announcementLogo}
                                    alt="announcementLogo"
                                  />
                                  <span>
                                    {removeUndefineds &&
                                    removeUndefineds.length > 0 &&
                                    announcement &&
                                    announcement.author
                                      ? announcement.author
                                      : authorOfNewsAndAnnouncements}
                                  </span>
                                </div>
                                <div className="newsDate">
                                  {moment(
                                    announcement && announcement.startDate,
                                  ).format('D MMM  YYYY')}
                                </div>
                              </div>
                              <span className="newsTitle">
                                {announcement && announcement.title}
                              </span>
                              <div className="noticeBoardLogo">
                                <img
                                  src={
                                    announcement &&
                                    announcement.newsImage &&
                                    announcement.newsImage[0] &&
                                    announcement.newsImage[0].imageUrl
                                  }
                                  alt={
                                    announcement &&
                                    announcement.newsImage &&
                                    announcement.newsImage[0] &&
                                    announcement.newsImage[0].imageUrl
                                  }
                                />
                              </div>
                              <div className="newsDescFull">
                                <p>
                                  {announcement &&
                                    announcement.descriptionPreview}
                                </p>
                              </div>
                            </div>

                            {listOfAnnouncements &&
                              listOfAnnouncements.length - 1 > index && (
                                <div className="borderLine">&nbsp;</div>
                              )}
                          </Link>
                        </div>
                      );
                    })}
                {listOfAnnouncements && listOfAnnouncements.length > 2 && (
                  <Link
                    className="rwadMoreLink"
                    onClick={() => {
                      setReadMore(!readMore);
                    }}
                  >
                    {linkName}
                  </Link>
                )}
              </div>
              <div className="buildingInfo">
                <div className="buildingDetails">
                  <h2>Your Building</h2>
                  <div className="BuildingImage">
                    {buildingImage && buildingImage.length > 0 && (
                      <img
                        src={
                          buildingImage &&
                          buildingImage[0] &&
                          buildingImage[0].imageUrl
                        }
                        alt="descLogo"
                      />
                    )}
                  </div>
                  <div className="buildingName">
                    {buildingName && buildingName}
                  </div>
                  <div className="buildingLocation">
                    {`${addressLine1}${
                      addressLine1 && addressLine2 && ', '
                    }${addressLine2}${addressLine2 && county && ', '}${county}`}
                    {(addressLine1 || addressLine2 || county) && city && ', '}
                    {city}
                    {city && postCode && ', '}
                    {postCode}
                  </div>
                </div>
                <Divider light />
                <div className="mapDetails">
                  <h2>Your area</h2>
                  {myPlaces && center && (
                    <div className="BuildingMap">
                      <MapContainer
                        isMarkerShown
                        myPlaces={myPlaces}
                        centerProp={center}
                        buildingVillage={buildingVillage}
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        county={county}
                        city={city}
                        postCode={postCode}
                        className="BuildingMapWrap"
                      />
                    </div>
                  )}
                </div>
                <div className="TflUpdate">
                  <div className="borderLine">&nbsp;</div>
                  <h2>TFL Updates</h2>
                  <div style={{ width: '100%' }}>
                    <TransportForLondon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default HomePage;
