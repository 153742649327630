import { GET_BUILDINGS } from '../utils/constants';

const getBuildings = action => ({
  type: GET_BUILDINGS,
  action,
});

export const getBuildingsData = buildings => dispatch => {
  dispatch(getBuildings(buildings));
};
