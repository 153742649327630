/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/button-has-type */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import playIcon from '../../images/play-button.svg';

const VideoImage = props => {
  const { news } = props;
  const [playVideo, setPlayVideo] = useState(false);
  if (playVideo && news && news.video) {
    return (
      <video
        src={news.video}
        autoPlay
        controls
        width="920"
        height="240"
        preload="none"
      />
    );
  }
  return (
    <>
      {(news && news.newsPreviewImage) ||
      (news && news.newsImage && news.newsImage.length > 0) ||
      (news && news.videoPreviewImage && news.videoPreviewImage.length > 0) ? (
        <Link
          to={`/newsdetails/${
            news &&
            news.title.replaceAll(' ', '-').replace(',', '').trim() &&
            news.title
              .replaceAll(' ', '-')
              .replace('?', '')
              .trim()
              .toLowerCase()
          }/${news && news.id}`}
        >
          <img
            src={
              (news && news.newsPreviewImage) ||
              (news &&
                news.newsImage &&
                news.newsImage[0] &&
                news.newsImage[0].imageUrl) ||
              (news &&
                news.videoPreviewImage &&
                news.videoPreviewImage[0] &&
                news &&
                news.videoPreviewImage[0].imageUrl)
            }
            alt="descLogo"
            style={{
              width: '655px',
              height: '315px',
              margin: '0 0 12px',
            }}
          />
        </Link>
      ) : (
        <></>
      )}
      {news && news.video && (
        <button
          className="imgPlayIcon"
          value={news && news.id}
          onClick={() => {
            setPlayVideo(true);
          }}
        >
          <img src={playIcon} alt="playIcon" />
        </button>
      )}
    </>
  );
};
export default VideoImage;
