import React from 'react';
import moment from 'moment';
import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import dowmArw from '../../images/down-arw.svg';
import { deskOptions } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  filterMain: {
    margin: '10px 0 0',
    padding: '0 25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterLeft: {
    width: '65%',
    '& ul': {
      display: 'flex',
      alignItems: 'center',
      '& li': {
        width: '20%',
        marginLeft: '25px',
        position: 'relative',
        '&:first-child': {
          marginLeft: '0',
        },
        '&:last-child': {
          '& button': {
            fontSize: '13px',
            lineHeight: '17px',
            color: '#838384',
            fontFamily: 'Conv_AtlasGrotesk-Regular-App',
            textTransform: 'capitalize',
            backgroundColor: 'transparent',
            // borderBottom: '1px solid #dfdfdf',
            borderRadius: 0,
            padding: 0,
          },
        },
        '& label': {
          fontSize: '13px',
          lineHeight: '17px',
          color: '#838384',
          fontFamily: 'Conv_AtlasGrotesk-Regular-App',
        },
      },
    },
  },
  formControl: {
    width: '100%',
  },
  formSelectBox: {
    marginTop: '0 !important',
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      width: '100%',
      borderBottom: '1px solid #dfdfdf',
      fontSize: '13px',
      lineHeight: '17px',
      color: '#838384',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      background: 'transparent !important',
      '&:after': {
        position: 'absolute',
        content: '""',
        background: `url(${dowmArw}) no-repeat 95% center !important`,
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        height: '10px',
        width: '10px',
      },
      '& div': {
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  filterRgt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  filterSearch: {
    position: 'relative',
    '& > div': {
      width: '100%',
      borderBottom: '1px solid #dfdfdf',
      '& div': {
        marginTop: '0 !important',
        paddingRight: '35px',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#838384',
        fontFamily: 'Conv_AtlasGrotesk-Regular-App',
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
    '& span': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 0,
      width: '13px',
      cursor: 'pointer',
    },
  },
}));

const SpaceFilters = props => {
  const classes = useStyles();
  const {
    spaceListCount,
    selectedDate,
    setSelectedDate,
    setClearFilter,
    selectedVillages,
    setSelectedVillages,
    uniqueVillages,
    currentDate,
    setDateSelected,
    setOffsetValue,
    setDeskOption,
    deskOption,
  } = props;

  const clearAllFilters = () => {
    setClearFilter(true);
    //  villageBuildngIds = [];
    setSelectedVillages([]);
    setSelectedDate('');
    setDeskOption('');
  };

  return (
    <div className="unitFilters">
      <div className={`${classes.filterLeft} unitFiltersLft`}>
        <ul>
          <li>
            <FormControl className={classes.formControl}>
              <InputLabel>Village</InputLabel>
              <Select
                className={classes.formSelectBox}
                multiple
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                value={selectedVillages}
                onChange={e => {
                  setClearFilter(false);
                  setSelectedVillages(e.target.value);
                  setOffsetValue(0);
                }}
              >
                {uniqueVillages &&
                  uniqueVillages.map(item => {
                    return (
                      <MenuItem value={item.village} key={item.id}>
                        {item.village}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </li>
          <li>
            <FormControl className={classes.formControl}>
              <InputLabel>Number of desks</InputLabel>
              <Select
                className={classes.formSelectBox}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                value={deskOption}
                onChange={e => {
                  setDeskOption(e.target.value);
                }}
              >
                {deskOptions &&
                  deskOptions.map(desk => (
                    <MenuItem value={desk}>{desk}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </li>
          <li>
            <FormControl className={classes.formControl}>
              <InputLabel>Availability</InputLabel>
              <Select
                className={classes.formSelectBox}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                value={selectedDate}
                onChange={e => {
                  setClearFilter(false);
                  setSelectedDate('');
                  setOffsetValue(0);
                  setSelectedDate(e.target.value);
                  if (e.target.value === 'Now') {
                    setDateSelected([new Date().toISOString()]);
                  } else if (
                    e.target.value.toString() ===
                    new Date().getFullYear().toString()
                  ) {
                    setDateSelected([
                      `${new Date().toISOString().split('T')[0]}T00:00:00.000Z`,
                      new Date(
                        `31 December ${new Date().getFullYear()} 23:30:00 GMT+0530`,
                      ).toISOString(),
                    ]);
                  } else {
                    setDateSelected([
                      new Date(
                        `01 January ${(
                          Number(new Date().getFullYear()) + 1
                        ).toString()} 05:30:00 GMT+0530`,
                      ).toISOString(),
                      new Date(
                        `01 January 2039 05:30:00 GMT+0530`,
                      ).toISOString(),
                    ]);
                  }
                }}
              >
                <MenuItem value="Now">Now</MenuItem>
                <MenuItem value={moment(currentDate).format('YYYY')}>
                  {moment(currentDate).format('YYYY')}
                </MenuItem>
                <MenuItem
                  value={`${Number(moment(currentDate).format('YYYY')) + 1} +`}
                >
                  {Number(moment(currentDate).format('YYYY')) + 1} +
                </MenuItem>
              </Select>
            </FormControl>
          </li>
          <li>
            <Button
              onClick={() => {
                clearAllFilters();
                setOffsetValue(0);
              }}
            >
              Clear filters
            </Button>
          </li>
        </ul>
      </div>
      {spaceListCount > 0 && (
        <div className="unitsAvlb">
          {`${
            spaceListCount > 1
              ? `${spaceListCount} Units Available`
              : `${spaceListCount} Unit Available`
          }`}
        </div>
      )}
    </div>
  );
};
export default SpaceFilters;
